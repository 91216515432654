import React from 'react';
import "./error.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';

class Error extends React.Component{

  constructor(props) {
    super(props);
  }

  render(){
    return (
      <div class='hover'>
        <section class="wrapper-esp">
          <div class="top">SIN ACCESO</div>
          <div class="bottom" aria-hidden="true">SIN ACCESO</div>
        </section>
        <section class="wrapper-esp">
        </section>
        <div class='background'>
          <div class='door'>✨🎉🎈🍺</div>
          <div class='rug'></div>
        </div>
        <div class='foreground'>
          <div class='bouncer'>
            <div class='head'>
              <div class='neck'></div>
              <div class='eye left'></div>
              <div class='eye right'></div>
              <div class='ear'></div>
            </div>
            <div class='body'></div>
            <div class='arm'></div>
          </div>
          <div class='poles'>
            <div class='pole left'></div>
            <div class='pole right'></div>
            <div class='rope'></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error;