import React, { createElement, useState } from "react";
import "./prospectos.css";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { Link } from "react-router-dom";
import infoMessage from "../../components/infoMessage/InfoMessage";
import DatePicker from "react-date-picker";

class Prospectos extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      loader: false,
      nombre: '',
      telefono: '',
      fecha_nacimiento: new Date(),
      correo: '',
      nota: '',
      id_embudo: '',
      ddEmbudos: [],
    };

    this.embudosRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.setState({loader: true});
    axios.get('/apiv2/CatalogosController.php/Embudos')
    .then(result => {
      this.setState({
        ddEmbudos: result.data.data,
        embudoSelected: result.data.data[0].value,
        id_embudo: result.data.data[0].value
      });
      this.embudosRef.current.value = parseInt(result.data.data[0].value); 
      if(this.props.match.params.id !== undefined){
        this.setState({loader: true});
        axios.get('/apiv2/ProspectosController.php/DetallesProspectos',{
          params:{
            id:this.props.match.params.id
          }
        })
        .then(result => {
          var datee = new Date(result.data.data[0].fecha_nacimiento);
          this.setState({
            loader: false,
            nombre: result.data.data[0].nombre,
            telefono: result.data.data[0].telefono,
            correo: result.data.data[0].correo,
            nota: result.data.data[0].nota,
            id_embudo: result.data.data[0].id_embudo,
            fecha_nacimiento: new Date(datee.setDate(datee.getDate() + 1)),
          });

          this.embudosRef.current.value = parseInt(result.data.data[0].id_embudo);
        })
        .catch(console.log);      
      }
    }).catch(console.log);
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Date]") {
      if (isNaN(event)) {
      } else {
        this.setState({fecha_nacimiento: event});
      }
    } else {
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  saveProvider(){
    if(this.state.nombre == ''){
      infoMessage(2, 'Error de captura','Capture el nombre para poder continuar.');
      return false;
    }
    if(this.state.telefono == ''){
      infoMessage(2, 'Error de captura','Capture el telefono para poder continuar.');
      return false;
    }
    if(this.state.telefono.length < 10){
      infoMessage(2, 'Error de captura','El telefono debe contener al menos 10 digitos.');
      return false;
    }
    if(this.state.correo == ''){
      infoMessage(2, 'Error de captura','Capture el telefono para poder continuar.');
      return false;
    }
    if(this.state.fecha_nacimiento == ''){
      infoMessage(2, 'Error de captura','Capture el telefono para poder continuar.');
      return false;
    }
    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/apiv2/ProspectosController.php/Prospectos',{
        id:this.props.match.params.id,
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        nota: this.state.nota,
        correo: this.state.correo,
        id_embudo: this.state.id_embudo,
        fecha_nacimiento: new Date(this.state.fecha_nacimiento.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        id_usuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/apiv2/ProspectosController.php/Prospectos',
      {
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        nota: this.state.nota,
        correo: this.state.correo,
        id_embudo: this.state.id_embudo,
        fecha_nacimiento: new Date(this.state.fecha_nacimiento.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        id_usuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-6">
                        <label className="control-label">Nombre</label>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" 
                        maxLength="60" value={this.state.nombre} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-6">
                        <label className="control-label">Correo</label>
                        <input type="text" className="form-control" placeholder="Correo" name="correo" 
                        maxLength="60" value={this.state.correo} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" placeholder="Telefono" name="telefono"
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Fecha De Nacimiento</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_nacimiento}/>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Embudo</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_embudo} name="id_embudo" ref={this.embudosRef}>
                          {this.state.ddEmbudos.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Nota</label>
                      <textarea type="text" className="form-control" name="nota"
                        value={this.state.nota} onChange={this.handleInputChange} ></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listaprospectos">
                        <button type="button" 
                          className="btn btn-danger btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.saveProvider()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Prospectos;
