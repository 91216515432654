import React, { useState} from 'react';
import Select from 'react-select';
import "./listaPolizasAutomovil.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link, Router, Redirect} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class ListaPolizasAutomovil extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10,
      ddMotivos: [],
      selectedOption: '',
      id_cancelacion_auto: '',
    };

    this.loadTableData();
    this.loadMotivosCancelacion();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkPerm();
    this.motivosRef = React.createRef();
  }
  
  handleInputChange(event) {
    this.setState({ id_cancelacion_auto: event.target.value });
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/apiv2/PolizasAutomovilController.php/PolizasAutomovil',
    {
      params:{
        q:this.state.filtro,
        currentPage:this.state.currentPage,
        totalPages:this.state.totalPages,
        limitPage:this.state.limitPage,
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data.result,
          totalPages: result.data.data.totalPages
        });
      },1000);
    })
    .catch(console.log);
  }

  loadMotivosCancelacion = () => {
    axios.get("/apiv2/CatalogosController.php/CancelacionesAutos")
    .then((result) => {
      console.log(result.data)
      console.log(result.data.data[1].value)
      this.setState({
        ddMotivos: result.data.data
      })
      this.motivosRef.current.value = parseInt(result.data.data[0].value);
    }).catch(console.log);

  }

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  handleSubmit(event) {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
    event.preventDefault();
  }

  estatusModal = (identificador, detalle, estatus) => {
    if(estatus == 1){
      var msg = 'activar';
      confirmAlert({
        message: (
          <div>
            <p>Desea {msg} el registro[{detalle}].</p>
          </div>
        ),
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.cambiarEstatus(identificador, estatus)
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      }  
      );

    }else{
      //Aqui va a ir el post 
      var msg = 'eliminar';
      confirmAlert({
        message: (
          <div>
            <p>Desea {msg} el registro[{detalle}].</p>
            <p>Elija el motivo</p>
            <Select options={this.state.ddMotivos} />
          </div>
        ),
        buttons: [
          {
            label: 'Continuar',
            onClick: () => {this.cambiarEstatus(identificador, estatus);}
            },
            {
              label: 'Cancelar'
            }
          ],
          overlayClassName: 'backGroundDialog'
        }  
        );
      }
    }
    

  cambiarEstatus = (identificador, estatus) => {
    this.setState({loader: true});
    axios.delete('/apiv2/PolizasAutomovilController.php/PolizasAutomovil?id='+identificador+'&estatus='+estatus+'&idusuario='+localStorage.getItem("NAid"))
    .then(result => {
      this.setState({loader: false});
      infoMessage(1, 'Mensaje del sistema', result.data.message);
      this.loadTableData();
    })
  }

  initialPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  prevPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage :Math.max(0, (this.state.currentPage - 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  nextPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : Math.min(this.state.totalPages, (this.state.currentPage + 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  lastPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : this.state.totalPages -1
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Polizas De Automovil</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                  	<form onSubmit={this.handleSubmit}>  
                      <div className="input-group input-group-sm mr-2" style={{width: "200px"}}>
                        <input type="text" className="form-control" placeholder="Buscar" 
                          value={this.state.filtro} onChange={this.handleChange}>
                        </input>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <Link to="/nuevapolizaautomovil">
                        <button type="button" 
                          className="btn btn-success btn-block btn-sm" 
                          style={{width: "150px"}} >
                          <i className="fa fa-plus-circle"></i> Agregar
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Compania</th>
                          <th>Poliza</th>
                          <th>Cliente</th>
                          <th>Inicio Vigencia</th>
                          <th>Agente</th>
                          <th>Tipo De Poliza</th>
                          <th>Serie</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result) => {
                          return(
                            <tr class="navbar-btn sidebar-toggle" data-toggle="offcanvas" role="button" data-tooltip="tooltip" 
                              title={result.descripcion} style={{backgroundColor: result.colorbg}}>
                              <td>{result.compania}</td>
                              <td>{result.poliza}</td>
                              <td>{result.cliente}</td>
                              <td>{result.fecha_emision}</td>
                              <td>{result.agente}</td>
                              <td>{result.tipo_polizas}</td>
                              <td>{result.serie}</td>
                              <td> 
                                <div class="d-flex justify-content-center">
                                 { result.fecha_registro == 'OPEN' && 
                                  <div className="input-group-append mr-2">
                                    <Link to={"/editarpolizaautomovil/"+result.id}>
                                      <button type="submit" className="btn btn-primary btn-sm">
                                        <i className="fas fa-pencil-alt"></i>
                                      </button>
                                    </Link>
                                  </div>
                                  }
                                  { result.estatus == 1 && 
                                    <div className="input-group-append">
                                      <button type="submit" className="btn btn-danger btn-sm" 
                                        onClick={() => this.estatusModal(result.id, result.poliza, 0)}>
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </div>
                                  }
                                  { result.estatus == 0 && 
                                    <div className="input-group-append">
                                      <button type="submit" className="btn btn-success btn-sm" 
                                        onClick={() => this.estatusModal(result.id, result.poliza, 1)}>
                                        <i className="fas fa-thumbs-up"></i>
                                      </button>
                                    </div>
                                  }
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix Table-Details">
                    <div className="pagination pagination-sm m-0 float-left">
                      <b>Pagina {this.state.currentPage + 1} de {this.state.totalPages}</b>
                    </div>
                    <div className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.initialPage()}>
                          <i className="fa fa-angle-double-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.prevPage()}>
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.nextPage()}>
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.lastPage()}>
                          <i className="fa fa-angle-double-right"></i>
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default ListaPolizasAutomovil;