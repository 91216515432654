import React from 'react';
import "./compararPolizasAutomovil.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class CompararPolizasAutomovil extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      filtro: '',
      loader: false,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10,
      polizasEmitidas:'',
      polizasPendientes:'',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  checarPolizas = () => {
    this.setState({loader: true});
    axios.post('/apiv2/CompararPolizasAutomovilController.php/CompararPolizas',
    {
      polizas:this.state.polizasEmitidas
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          polizasPendientes: result.data.data
        });
      },1000);
    })
    .catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Comparar Polizas Automovil</h3>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="form-group" style={{padding:"10px"}}>
                        <div className="mr-2">
                          <button type="button" 
                            className="btn btn-success btn-block btn-sm" 
                            style={{width: "250px"}} 
                            onClick={() => this.checarPolizas()}>
                            <i className="fa fa-search"></i> Analizar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row" style={{padding:"10px", height:"700px"}}>
                    <div className="form-group col-md-6">
                      <div className="form-group">
                        <label for="inputAddress">Polizas Emitidas</label>
                        <textarea type="text" className="form-control" name="polizasEmitidas"
                          value={this.state.polizasEmitidas} onChange={this.handleInputChange} style={{height:"620px"}}></textarea>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-group">
                        <label for="inputAddress">Polizas Sin Capturar</label>
                        <textarea type="text" className="form-control" name="polizasPendientes"
                          value={this.state.polizasPendientes} onChange={this.handleInputChange}  style={{height:"620px"}} disabled></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default CompararPolizasAutomovil;