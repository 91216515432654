import React from 'react';
import "./llamadaCalidad.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class LlamadaCalidad extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      poliza: '',
      detalles: '',
      cliente: '',
      telefono: '',
      correo: '',
      id_tipo_poliza: '',
      id_agente: '',
      id_cliente:'',
      fecha_emision:'',
      telefono_cliente:'',
      pago_inicial:'',
      pago_subsecuente:'',
      comision:0,
      fecha_lista:'',
      ganancia_bruta:0,
      gastos_administrativos:0,
      ddResultadoLlamada: [],
      historialLlamadas: [],
      id_tipo_llamada:'',
      comentarioLlamada:'',
      ddTiposPolizas: [],
      ddAgentes: [],
      calendario:[],
      XXXXXXXXXXXXXXXXXXXXX:0,
      resultado_llamada:0,
      showDialog:0,
      cliente_real:0,
      agendar:0,
      comentario_agenda:'',
      fecha_agenda:'',
      hora_agenda:''
    };

    this.tiposPolizasRef = React.createRef();
    this.agentesRef = React.createRef();
    this.tiposLlamadasRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeDateAgenda = this.handleChangeDateAgenda.bind(this);

    this.reloadPage();
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  reloadPage(){
    axios.get('/apiv2/CatalogosController.php/Agentes')
    .then(result => {
      this.setState({
        ddAgentes: result.data.data,
        agenteSelected: result.data.data[0].value,
        id_agente: result.data.data[0].value
      });
      this.agentesRef.current.value = parseInt(result.data.data[0].value); 

      axios.get('/apiv2/CatalogosController.php/TiposPolizas')
      .then(result => {
        this.setState({
          loader: false,
          ddTiposPolizas: result.data.data,
          polizaSelected: result.data.data[0].value,
          id_tipo_poliza: result.data.data[0].value
        });
        this.tiposPolizasRef.current.value = parseInt(result.data.data[0].value); 

        if(this.props.match.params.id !== undefined){
          this.setState({loader: true});
          axios.get('/apiv2/LlamadasCalidadController.php/DetallesPolizaAutomovil',{
            params:{
              id:this.props.match.params.id,
            }
          })
          .then(result => {
            this.setState({
              loader: false,
              poliza: result.data.data.datosCliente[0].poliza,
              detalles: result.data.data.datosCliente[0].descripcion,
              cliente: result.data.data.datosCliente[0].cliente,
              telefono: result.data.data.datosCliente[0].telefono,
              telefono_cliente: result.data.data.datosCliente[0].telefono_cliente,
              correo: result.data.data.datosCliente[0].correo,
              pago_inicial: result.data.data.datosCliente[0].pago_inicial,
              pago_subsecuente: result.data.data.datosCliente[0].pago_subsecuente,
              fecha_emision:result.data.data.datosCliente[0].fecha_emision,
              id_tipo_poliza: result.data.data.datosCliente[0].id_tipo_poliza,
              id_agente: result.data.data.datosCliente[0].id_agente,
              id_cliente: result.data.data.datosCliente[0].id_cliente,
              ganancia_bruta: result.data.data.datosCliente[0].comision,
              gastos_administrativos: result.data.data.datosCliente[0].cobro_administrativo,
              calendario:result.data.data.calendario
            });

            this.tiposPolizasRef.current.value = parseInt(result.data.data.datosCliente[0].id_tipo_poliza);
            this.agentesRef.current.value = parseInt(result.data.data.datosCliente[0].id_agente);
          })
          .catch(console.log);      
        }

      }).catch(console.log);

    }).catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    this.setState({fecha_lista: event});
  }

  handleChangeDateAgenda(event) {
    this.setState({fecha_agenda: event});
  }

  guardarLlamada(){    
    this.setState({loader: true});
    axios.post('/apiv2/LlamadasCalidadController.php/AgregarLlamada',
    {
      resultado_llamada: this.state.resultado_llamada,
      cliente_real: this.state.cliente_real,
      agendar: this.state.agendar,
      comentario_agenda: this.state.comentario_agenda,
      fecha_agenda: this.state.fecha_agenda,
      hora_agenda: this.state.hora_agenda,
      id_cliente: this.state.id_cliente,
      id_usuario: localStorage.getItem('NAid')
    })
    .then(result => {
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        this.reloadPage();
      },1200);
    })
    .catch(console.log);
  }

  agregarTelefono(){    
    this.setState({loader: true});
    axios.post('/apiv2/LlamadasCalidadController.php/AgregarTelefono',
    {
      telefono: this.state.telefono_cliente, 
      id_cliente: this.state.id_cliente
    })
    .then(result => {
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        this.reloadPage();
      },1200);
    })
    .catch(console.log);
  }

  cambiarFechaLista(){
    this.setState({loader: true});
    axios.post('/apiv2/LlamadasCalidadController.php/CambiarFechaLista',
    {
      id_cliente: this.state.id_cliente,
      fecha: this.state.fecha_lista
    })
    .then(result => {
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        this.reloadPage();
      },1200);
    })
    .catch(console.log);
  }


/* CONDICIONES DE DIALOGO */

/*
        resultado_llamada
        cliente_real
        agendar
        comentario_agenda
        fecha_agenda
        hora_agenda

*/

  moverDialogo(showDialog){
    if(showDialog == 5){
      this.setState({
        agendar:0
      }); 
    }
    this.setState({
      showDialog:showDialog
    });
  }

  finalizarLlamada(){
    confirmAlert({
        message: '¿Desea finalizar la llamada?.',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => {
              this.guardarLlamada();
            }
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
  }


  resultadoLlamada(tipoLlamada){//DIALOGO 0
    if(tipoLlamada == 1){
      this.setState({
        resultado_llamada:tipoLlamada,
        showDialog:1,
        cliente_real:0
      });
    }else{
      this.setState({
        resultado_llamada:tipoLlamada,
        showDialog:4,
        cliente_real:0
      });
    }
  }

  checarCliente(respuesta) {//DIALOGO 1
    if(respuesta == 1){
      this.setState({
        showDialog:2,
        cliente_real:1
      });
    }else{
      this.setState({
        showDialog:3,
        cliente_real:2
      });
    } 
  }

  agendarLlamada(){
    this.setState({
      showDialog:7,
      agendar:1
    });
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>INFORMACION DEL CLIENTE</b>
                      </h3>
                      <div className="form-row justify-content-end">
                        <div className="mr-2">
                          <button type="button" 
                            className="btn-sm btn-success btn-block"
                            onClick={() => this.agregarTelefono()}>
                            <i className="fa fa-save"></i> Guardar Telefono
                          </button>
                        </div>
                        <Link to="/listallamadascalidad">
                          <button type="button" 
                            className="btn-sm btn-danger btn-block">
                            <i className="fa fa-arrow-circle-left"></i> Volver
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label className="control-label">Poliza</label>
                        <input type="text" className="form-control" name="poliza" 
                        maxLength="60" value={this.state.poliza} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-5">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-5">
                        <label className="control-label">Agente</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_agente} name="id_agente" ref={this.agentesRef} disabled>
                          {this.state.ddAgentes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" name="telefono" 
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Telefono 2</label>
                        <input type="text" className="form-control" name="telefono_cliente" 
                        maxLength="60" value={this.state.telefono_cliente} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Inicio De Vigencia</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_emision} disabled={true}/>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef} disabled>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Pago Inicial</label>
                        <input type="number" className="form-control" name="pago_inicial" 
                        maxLength="10" value={this.state.pago_inicial} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Pago Subsecuente</label>
                        <input type="number" className="form-control" name="pago_subsecuente" 
                        maxLength="10" value={this.state.pago_subsecuente} onChange={this.handleInputChange} disabled></input>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} disabled></textarea>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-6">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card">
                      {this.state.showDialog == 0 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW dialogInterno">Capture el resultado de la llamada</h5>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.resultadoLlamada(1)}>Efectiva</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.resultadoLlamada(2)}>No Efectiva</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.resultadoLlamada(3)}>Sin Linea</a>
                        </div>
                      }
                      {this.state.showDialog == 1 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW">Buen dia, mi nombre es {localStorage.getItem('NAuser')} me comunico de Flores Rubio y Asociados, 
                            somos el corporativo que administra la póliza del seguro de auto que adquirió recientemente, ¿me comunico con el Sr/Sra. {this.state.cliente}?</h5>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(0)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.checarCliente(2)}>No es el cliente</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.checarCliente(1)}>Si es el cliente</a>
                        </div>
                      }
                      {this.state.showDialog == 2 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW">Esta llamada es realizada desde el departamento de calidad para corroborar los datos que vienen incluidos en su póliza.</h5>
                          <h5 class="card-title dialogW">Está a nombre de {this.state.cliente} y el vehículo es un {this.state.detalles}
                             ¿Estos datos son correctos?</h5>
                          <h5 class="card-title dialogW dialogInterno">Si algún dato es incorrecto, se requiere hacer el cambio</h5>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(1)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(5)}>Continuar</a>
                        </div>
                      }
                      {this.state.showDialog == 3 && 
                        <div class="card-body">
                          <p class="card-title dialogW">Le ofrecemos una disculpa, que tenga un buen día.</p>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(1)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.finalizarLlamada()}>Finalizar Llamada</a>
                        </div>
                      }
                      {this.state.showDialog == 4 && 
                        <div class="card-body">
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(0)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.finalizarLlamada()}>Finalizar Llamada</a>
                        </div>
                      }
                      {this.state.showDialog == 5 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW">Le informo que a este mismo número se puede comunicar en caso de cualquier duda o aclaración que tenga; 
                            parte de nuestro servicio también incluye comunicarle sobre el vencimiento de su póliza 15 días antes por este mismo medio
                             o si prefiere podemos comunicarnos vía whatsapp.
                          </h5>
                          <h5 class="card-title dialogW">¿Le gustaría que le recordemos a este mismo número o desea agregar otro?</h5>
                          <h5 class="card-title dialogW dialogInterno">Si algún dato es incorrecto, se requiere hacer el cambio</h5>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(2)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(8)}>Continuar</a>
                        </div>
                      }
                      {this.state.showDialog == 6 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW">Perfecto, ¿sobre cuál producto le interesa más información?.
                          </h5>
                          <h5 class="card-title dialogW dialogInterno">Capturar información del producto</h5>
                          <div className="form-group">
                            <textarea type="text" className="form-control" name="comentario_agenda"
                              value={this.state.comentario_agenda} onChange={this.handleInputChange}></textarea>
                          </div>
                          <h5 class="card-title dialogW">¿A qué fecha y hora podemos comunicarnos?.
                          </h5>
                          <h5 class="card-title dialogW dialogInterno">Capturar fecha y hora</h5>
                          <div className="form-group col-md-5">
                            <label className="control-label">Fecha</label>
                            <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDateAgenda} 
                              value={this.state.fecha_agenda} />
                            <label className="control-label">Hora</label>
                            <input type="text" className="form-control" name="hora_agenda" 
                            maxLength="60" value={this.state.hora_agenda} onChange={this.handleInputChange}></input>
                          </div>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(8)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.agendarLlamada()}>Continuar</a>
                        </div>
                      }
                      {this.state.showDialog == 7 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW">Le agradezco por haber tomado mi llamada, le atendió {localStorage.getItem('NAuser')} que tenga un excelente dia
                          </h5>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(6)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.finalizarLlamada()}>Finalizar Llamada</a>
                        </div>
                      }
                      {this.state.showDialog == 8 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW">Finalizando mi llamada, le informo que al igual que el servicio que adquirió también manejamos otros tipos, tales como
                            Seguro de gastos médicos, para la salud de sus seres queridos.
                            Ahorro para el retiro, ya que hoy en día no se cuenta con pensión para la jubilación y 
                            Seguro de vida para dejar amparada a su familia en caso de fallecimiento.
                          </h5>
                          <h5 class="card-title dialogW">¿Le interesa más información sobre algún producto?</h5>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(5)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(9)}>No Me Interesa</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(6)}>Me Interesa</a>
                        </div>
                      }
                      {this.state.showDialog == 9 && 
                        <div class="card-body">
                          <h5 class="card-title dialogW">Le agradezco por haber tomado mi llamada, le atendió {localStorage.getItem('NAuser')} que tenga un excelente dia
                          </h5>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.moverDialogo(8)}>Volver</a>
                          <a href="#" class="btn btn-primary m-2" onClick={() => this.finalizarLlamada()}>Finalizar Llamada</a>
                        </div>
                      }
                    </div>
                    <div class="card-header line-esp">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="control-label">Ocultar De Lista</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_lista} />
                      </div>
                      <div className="form-group col-md-4">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.cambiarFechaLista()} >
                          <i className="fa fa-calendar-week"></i> Actualizar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default LlamadaCalidad;