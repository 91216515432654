import React from 'react';
import "./infoMessage.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function InfoMessage(tipo, titulo, mensaje){
  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                <div class="form-row">
                  <div className="col-md-3">
                    <div class="row justify-content-md-center">
                      {tipo == 1 ?
                        <i style={{color:"green"}} className="fas fa-check-circle fa-4x pr-3" aria-hidden="true"></i>:
                        <i style={{color:"red"}} className="fas fa-times-circle fa-4x pr-3" aria-hidden="true"></i>
                      }
                    </div>
                  </div>
                  <div className="col-md-9">
                    <h3>{titulo}</h3>
                    <p>{mensaje}</p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div class="form-row justify-content-md-center">
                  <button type="button" 
                    className="btn btn-dark btn-block mr-2" 
                    style={{width: "150px"}} 
                    onClick={() => {onClose()}}>
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    })
  );
}