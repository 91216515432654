import React from 'react';
import "./reporteRenovacionesAutomovil.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

class ReporteRenovacionesAutomovil extends React.Component{
  constructor(props) {
    super(props);

    let fecha = new Date();

    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      ddAgentes: [],
      agenteSelected: 0,
      id_agente: 0,
      filtroFechas:[ 
        new Date(fecha.setDate(fecha.getDate()-7)),
        new Date(fecha.setDate(fecha.getDate()+7))
      ],
      selectedRow: 0,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.agentesRef = React.createRef();
    this.loadAgentes();
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/apiv2/ConsultasReportesController.php/RenovacionesAutomovilDetalle',
    {
      params:{
        idAgente:this.state.id_agente,
        fechaInicial:new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        fechaFinal:new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data
        });
      },1000);
    })
    .catch(console.log);
  }

  loadAgentes = () => {
    this.setState({loader: true});
    axios.get('/apiv2/ConsultasReportesController.php/RenovacionesAutomovilCantidad',
    {
      params:{
        fechaInicial:new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        fechaFinal:new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
      }
    })
    .then(result => {
      if(Object.keys(result.data.data).length === 0){
        this.setState({
          loader: false,
          ddAgentes: [],      
          agenteSelected: 0,
          id_agente: 0,
        });
      }else{
        this.setState({
          loader: false,
          ddAgentes: result.data.data,
          agenteSelected: result.data.data[0].value,
          id_agente: result.data.data[0].value
        });
        this.agentesRef.current.value = parseInt(result.data.data[0].value); 
      }
    }).catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Array]") {
        this.setState({filtroFechas: event});
    }
  }

  handleSubmit(event) {
    this.setState({loader: true});
    setTimeout(() => {
      this.loadTableData();
    }, 800);
    event.preventDefault();
  }
  selectRow = (id) => {
    this.setState({
      selectedRow: id,
      currentPagePagos: 0,
      totalPagesPagos: 1,
      limitPagePagos: 10,
    });
    this.loadTableDataPagos(id);
  }

  generarPDF(){
    var fecha1 = new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0];
    var fecha2 = new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0];
    window.open('/apiv2/ReportesController.php/RenovacionesAutomovil?'
      +'idAgente='+this.state.id_agente
      +'&fechaInicial='+fecha1
      +'&fechaFinal='+fecha2
      ).focus();
  }

  generarExcel(){
    var fecha1 = new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0];
    var fecha2 = new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0];
    window.open('/apiv2/ReportesController.php/RenovacionesAutomovilExcel?'
      +'idAgente='+this.state.id_agente
      +'&fechaInicial='+fecha1
      +'&fechaFinal='+fecha2
      ).focus();
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Renovaciones De Automoviles</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                    <div className="mr-2">
                      <DateRangePicker className="mr-2" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                        value={this.state.filtroFechas}/>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadAgentes()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <form onSubmit={this.handleSubmit}>  
                      <div className="form-group required col-md-12">
                        <select class="custom-select" onChange={this.handleInputChange} style={{width: "240px"}} 
                          value={this.state.id_agente} name="id_agente" ref={this.agentesRef}>
                          {this.state.ddAgentes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </form>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-danger btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.generarPDF()}>
                        <i className="fas fa-file-pdf"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-success btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.generarExcel()}>
                        <i className="fas fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                  <form onSubmit={this.handleSubmit} style={{padding:"10px"}}>
                    <div className="form-row">
                      <div className="form-group required col-md-12">
                        <div className="table-wrapper-scroll-y my-custom-scrollbar">
                          <table className="table table-bordered table-striped mb-0">
                            <thead>
                              <tr className="Table-Details">
                                <th>Compañia</th>       
                                <th>Poliza</th>                                  
                                <th>Agente</th>                               
                                <th>Cliente</th>
                                <th>Descripcion</th>
                                <th>Fecha De Pago</th>
                                <th>Serie</th>
                                <th>Estatus</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.dataTable.map((result) => {
                                return(
                                  <tr>
                                    <td>{result.compania}</td> 
                                    <td>{result.poliza}</td>                                      
                                    <td>{result.agente}</td>                                     
                                    <td>{result.cliente}</td>
                                    <td>{result.descripcion}</td>
                                    <td>{result.fechas_de_pago}</td>
                                    <td>{result.serie_pago}</td>
                                    <td>
                                      <span style={{marginLeft:"10px"}} className={result.class_name}>{result.fecha_pagada}</span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default ReporteRenovacionesAutomovil;