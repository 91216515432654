import React from 'react';
import "./clientes.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link } from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';

class Clientes extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      nombre: '',
      telefono: '',
      correo: '',
      fecha_nacimiento:new Date(),
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    if(this.props.match.params.id !== undefined){
      this.setState({loader: true});
      axios.get('/apiv2/ClientesController.php/DetallesClientes',{
        params:{
          id:this.props.match.params.id,
        }
      })
      .then(result => {
        this.setState({
          loader: false,
          nombre: result.data.data[0].nombre,
          telefono: result.data.data[0].telefono,
          correo: result.data.data[0].correo,
          fecha_nacimiento: new Date(result.data.data[0].fecha_nacimiento+'T00:00:00-07:00')
        });
      })
      .catch(console.log);      
    }
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Date]") {
      if (isNaN(event)) {
      } else {
        this.setState({fecha_nacimiento: event});
      }
    } else {
    }
  }

  saveProvider(){
    if(this.state.nombre == ''){
      infoMessage(2, 'Error de captura','Capture el nombre para poder continuar.');
      return false;
    }
    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/apiv2/ClientesController.php/Clientes',{
        id:this.props.match.params.id,
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        domicilio: this.state.domicilio,
        correo: this.state.correo,
        fecha_nacimiento: this.state.fecha_nacimiento,
        idusuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/apiv2/ClientesController.php/Clientes',
      {
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        domicilio: this.state.domicilio,
        correo: this.state.correo,
        fecha_nacimiento: this.state.fecha_nacimiento,
        idusuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-6">
                        <label className="control-label">Nombre</label>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" 
                        maxLength="60" value={this.state.nombre} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-6">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" placeholder="Telefono" name="telefono"
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-6">
                        <label className="control-label">Correo</label>
                        <input type="text" className="form-control" placeholder="Correo" name="correo"
                        maxLength="60" value={this.state.correo} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-6">
                        <label className="control-label">Fecha De Nacimiento</label>
                        <DatePicker className="form-control" format="yyyy-MM-dd" onChange={this.handleChangeDate} 
                          value={this.state.fecha_nacimiento}/>
                      </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listaclientes">
                        <button type="button" 
                          className="btn btn-danger btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.saveProvider()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Clientes;