import React from 'react';
import "./reporteProduccionAgentes.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

class ReporteProduccionAgentes extends React.Component{
  constructor(props) {
    super(props);

    let fecha = new Date();

    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10,
      filtroFechas:[ 
        new Date(fecha.setDate(fecha.getDate()-7)),
        new Date(fecha.setDate(fecha.getDate()+7))
      ],
      selectedRow: 0,
    };
    this.loadTableData();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/apiv2/ConsultasReportesController.php/ProduccionPorAgente',
    {
      params:{
        q:this.state.filtro,
        currentPage:this.state.currentPage,
        totalPages:this.state.totalPages,
        limitPage:this.state.limitPage,
        fechaInicial:new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0],//this.state.filtroFechas[0].toISOString().split('T')[0],
        fechaFinal:new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0],//this.state.filtroFechas[1].toISOString().split('T')[0],
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data
        });
      },1000);
    })
    .catch(console.log);
  }

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Array]") {
        this.setState({filtroFechas: event});
    }
  }

  handleSubmit(event) {
    this.setState({loader: true});
    setTimeout(() => {
      this.loadTableData();
    }, 800);
    event.preventDefault();
  }
  selectRow = (id) => {
    this.setState({selectedRow: id});
  }

  generarPDF(){
    var fecha1 = new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0];//this.state.filtroFechas[0].toISOString().split('T')[0];
    var fecha2 = new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0];//this.state.filtroFechas[1].toISOString().split('T')[0];
    window.open('/apiv2/ReportesController.php/PagosPolizas?'
      +'filtro='+this.state.filtro
      +'&fechaInicial='+fecha1
      +'&fechaFinal='+fecha2  
      ).focus();
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Produccion Por Agente</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                    <form onSubmit={this.handleSubmit}>  
                      <div className="input-group input-group-sm mr-2" style={{width: "200px"}}>
                        <input type="text" className="form-control" placeholder="Buscar" 
                          value={this.state.filtro} onChange={this.handleChange}>
                        </input>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mr-2">
                      <DateRangePicker className="mr-2" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                        value={this.state.filtroFechas}/>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-danger btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.generarPDF()}>
                        <i className="fa fa-file-pdf"></i>
                      </button>
                    </div>
                  </div>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Agente</th>
                          <th>Compañia</th>
                          <th>Cliente</th>
                          <th>Descripcion</th>
                          <th>Cantidad</th>
                          <th>Gastos Admin</th>
                          <th>Usuario</th>
                          <th>Tipo De Poliza</th>
                          <th>Fecha</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result) => {
                          return(
                            <tr className={this.state.selectedRow == result.id ? 'Selected-Table-Row': null}
                              onDoubleClick={() => this.selectRow(result.id)}>
                              <td>{result.poliza}</td>
                              <td>{result.compania}</td>
                              <td>{result.cliente}</td>
                              <td>{result.descripcion}</td>
                              <td>${result.cantidad}</td>
                              <td>{result.cantidad_administrativa}</td>
                              <td>{result.usuario}</td>
                              <td>{result.tipo_poliza}</td>
                              <td>{result.fecha_registro}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ReporteProduccionAgentes;