import React from 'react';
import "./listaAgentesSeguros.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class ListaAgentesSeguros extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10
    };
    this.loadTableData();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/apiv2/AgentesController.php/Agentes',
    {
      params:{
        q:this.state.filtro,
        currentPage:this.state.currentPage,
        totalPages:this.state.totalPages,
        limitPage:this.state.limitPage,
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data.result,
          totalPages: result.data.data.totalPages
        });
      },1000);
    })
    .catch(console.log);
  }

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  handleSubmit(event) {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
    event.preventDefault();
  }

  deleteModal = (identificador, detalle) => {
    confirmAlert({
      message: 'Desea eliminar el registro['+detalle+'].',
      buttons: [
        {
          label: 'Continuar',
          onClick: () => this.deleteProvider(identificador)
        },
        {
          label: 'Cancelar'
        }
      ],
      overlayClassName: 'backGroundDialog'
    });
  }

  deleteProvider = (identificador) => {
    this.setState({loader: true});
    axios.delete('/apiv2/AgentesController.php/Agentes?id='+identificador+'&idusuario='+localStorage.getItem("NAid"))
    .then(result => {
      this.setState({loader: false});
      infoMessage(1, 'Mensaje del sistema', result.data.message);
      this.loadTableData();
    })
    .catch(console.log);
  }

  initialPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : 0
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  prevPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage :Math.max(0, (this.state.currentPage - 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  nextPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : Math.min(this.state.totalPages, (this.state.currentPage + 1))
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  lastPage = () => {
    this.setState({loader: true});
    this.setState({
      currentPage : this.state.totalPages -1
    });
    setTimeout(() => {
      this.loadTableData();
    }, 800);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Agentes Seguros</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                  	<form onSubmit={this.handleSubmit}>  
                      <div className="input-group input-group-sm mr-2" style={{width: "200px"}}>
                        <input type="text" className="form-control" placeholder="Buscar" 
                          value={this.state.filtro} onChange={this.handleChange}>
                        </input>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2">
                      <Link to="/nuevoagenteseguro">
                        <button type="button" 
                          className="btn btn-success btn-block btn-sm" 
                          style={{width: "150px"}} >
                          <i className="fa fa-plus-circle"></i> Agregar
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Agente</th>
                          <th>Telefono</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result) => {
                          return(
                            <tr>
                              <td>{result.nombre}</td>
                              <td>{result.telefono}</td>
                              <td> 
                                <div class="d-flex justify-content-center">
                                  <div className="input-group-append mr-2">
                                    <Link to={"/agentesespeciales/"+result.id}>
                                      <button type="submit" className="btn btn-success btn-sm">
                                        <i className="fas fa-file-invoice-dollar"></i>
                                      </button>
                                    </Link>
                                  </div>
                                  <div className="input-group-append mr-2">
                                    <Link to={"/editaragenteseguro/"+result.id}>
                                      <button type="submit" className="btn btn-primary btn-sm">
                                        <i className="fas fa-pencil-alt"></i>
                                      </button>
                                    </Link>
                                  </div>
                                  <div className="input-group-append">
                                    <button type="submit" className="btn btn-danger btn-sm" 
                                      onClick={() => this.deleteModal(result.id, result.nombre)}>
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix Table-Details">
                    <div className="pagination pagination-sm m-0 float-left">
                      <b>Pagina {this.state.currentPage + 1} de {this.state.totalPages}</b>
                    </div>
                    <div className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.initialPage()}>
                          <i className="fa fa-angle-double-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.prevPage()}>
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.nextPage()}>
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.lastPage()}>
                          <i className="fa fa-angle-double-right"></i>
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default ListaAgentesSeguros;