import React, { createElement, useState } from "react";
import "./CancelacionesAutos.css";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { Link } from "react-router-dom";
import infoMessage from "../../components/infoMessage/InfoMessage";
import DatePicker from "react-date-picker";

class CancelacionesAutos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            nombre:'',
            descripcion:''
        };

        this.loadAll();
        this.checkPerm();
        this.handleInputChange = this.handleInputChange.bind(this);
        
    }

    checkPerm = async () => {
        var url = window.location.href;
        var lastSegment = url.split("/");
        var ruta = lastSegment[3];
        var perms = JSON.parse(localStorage.getItem("NApermisos"));
        var rett = true;
        await Promise.all(
          perms.map(async (perm) => {
            if (perm.uri == ruta) {
              rett = false;
              return false;
            }
          })
        );
        if (rett) {
          this.props.history.push("/error");
        }
      };

      handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        if (target.type === "checkbox") {
          if (target.checked) {
            this.state.hobbies[value] = value;
          } else {
            this.state.hobbies.splice(value, 1);
          }
        } else {
          this.setState({
            [name]: value,
          });
        }
      }

    

    loadAll() {
        this.setState({loader: true});
        axios.get("/apiv2/CancelacionesAutosController.php/CancelacionesAutos",
        {
            params: {
                id_cancelacion_auto: this.props.match.params.id_cancelacion_auto
            }
        })
        .then((result) => {
            console.log(result)
            this.setState({
                nombre: result.data.data[(this.props.match.params.id_cancelacion_auto)-1].nombre,
                descripcion: result.data.data[(this.props.match.params.id_cancelacion_auto)-1].descripcion
            })
        }).catch(console.log)
    }

    saveAll(){
        console.log('entra')
        this.setState({loader:true});
        console.log(this)
        if(this.state.nombre == '' || this.state.descripcion == ''){
            infoMessage(
                2,
                "Error de captura",
                "Por favor complete todos los campos"
            )
            return false;
        }
        if (this.props.match.params.id_cancelacion_auto != undefined){
            axios.put("/apiv2/CancelacionesAutosController.php/CancelacionesAutos",{
                id_cancelacion_auto: this.props.match.params.id_cancelacion_auto,
                nombre: this.state.nombre,
                descripcion: this.state.descripcion
            })
            .then(result => {
                this.setState({ loader: false });
                let tipo = 1;
                if (result.data.success) {
                  tipo = 1;
                } else {
                  tipo = 2;
                }
                infoMessage(tipo, "Mensaje del sistema", result.data.message);
              })
              .catch(console.log);
        }else{
            axios.post("/apiv2/CancelacionesAutosController.php/CancelacionesAutos", {
            nombre: this.state.nombre,
            descripcion: this.state.descripcion
        })
        }
        
    }

    render() {
        return (
          
            <div className="wrapper" style={{ paddingTop: "10px" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <form>
                        <div className="form-row">
                          <div className="form-group required col-md-6">
                            <label className="control-label">Nombre</label>
                            <input
                          type="text"
                          className="form-control"
                          name="nombre"
                          maxLength="60"
                          value={this.state.nombre}
                          onChange={this.handleInputChange}
                        ></input>
                          </div>
                          <div className="form-group required col-md-6">
                            <label className="control-label">Descripcion</label>
                            <input
                          type="text"
                          className="form-control"
                          name="descripcion"
                          maxLength="60"
                          value={this.state.descripcion}
                          onChange={this.handleInputChange}
                        ></input>
                          </div>
                        </div>
                        <div className="form-row justify-content-md-center">
                      <Link to="/listacancelacionesautos">
                        <button
                          type="button"
                          className="btn btn-dark btn-block mr-2"
                          style={{ width: "150px" }}
                        >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        style={{ width: "150px" }}
                        onClick={() => this.saveAll()}
                      >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        );
      }
}
export default CancelacionesAutos;