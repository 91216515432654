import React from "react";
import "./App.css";
import Header from "./Header";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import ListaAgentesSeguros from "./pages/listaAgentesSeguros/ListaAgentesSeguros";
import AgentesSeguros from "./pages/agentesSeguros/AgentesSeguros";
import ListaClientes from "./pages/listaClientes/ListaClientes";
import Clientes from "./pages/clientes/Clientes";
import ListaPolizasAutomovil from "./pages/listaPolizasAutomovil/ListaPolizasAutomovil";
import PolizasAutomovil from "./pages/polizasAutomovil/PolizasAutomovil";
import ListaPagosPolizasAutomovil from "./pages/listaPagosPolizasAutomovil/ListaPagosPolizasAutomovil";
import LlamadaPolizasAutomovil from "./pages/llamadaPolizasAutomovil/LlamadaPolizasAutomovil";
import CapturaPolizasAutomovil from "./pages/capturaPolizasAutomovil/CapturaPolizasAutomovil";
import LlamadaRenovacionesAutomovil from "./pages/llamadaRenovacionesAutomovil/LlamadaRenovacionesAutomovil";
import Multicotizador from "./pages/multicotizador/Multicotizador";
import ReportePagosPolizas from "./pages/reportePagosPolizas/ReportePagosPolizas";
import ReporteEmisionPolizas from "./pages/reporteEmisionPolizas/ReporteEmisionPolizas";
import Cumpleanios from "./pages/cumpleanios/Cumpleanios";
import ReportePagosAgentes from "./pages/reportePagosAgentes/ReportePagosAgentes";
import ListaPolizasGMM from "./pages/listaPolizasGMM/ListaPolizasGMM";
import PolizasGMM from "./pages/polizasGMM/PolizasGMM";
import ListaPagosPolizasGMM from "./pages/listaPagosPolizasGMM/ListaPagosPolizasGMM";
import LlamadaPolizasGMM from "./pages/llamadaPolizasGMM/LlamadaPolizasGMM";
import CobrosPolizasAutomovil from "./pages/cobrosPolizasAutomovil/CobrosPolizasAutomovil";
import ReporteProduccionAgentes from "./pages/reporteProduccionAgentes/ReporteProduccionAgentes";
import ListaLlamadasCalidad from "./pages/listaLlamadasCalidad/ListaLlamadasCalidad";
import LlamadaCalidad from "./pages/llamadaCalidad/LlamadaCalidad";
import ReporteLlamadasCalidadAutomovil from "./pages/reporteLlamadasCalidadAutomovil/ReporteLlamadasCalidadAutomovil";
import CompararPolizasAutomovil from "./pages/compararPolizasAutomovil/CompararPolizasAutomovil";
import ReporteCobranzaAutomovil from "./pages/reporteCobranzaAutomovil/ReporteCobranzaAutomovil";
import ReporteRenovacionesAutomovil from "./pages/reporteRenovacionesAutomovil/ReporteRenovacionesAutomovil";
import ReporteRenovacionesGMM from "./pages/reporteRenovacionesGMM/ReporteRenovacionesGMM";
import AgentesSegurosEspecial from "./pages/agentesSegurosEspecial/AgentesSegurosEspecial";
import ReporteCobranzaGMM from "./pages/reporteCobranzaGMM/ReporteCobranzaGMM";
import EndososAutomovil from "./pages/endososAutomovil/EndososAutomovil";
import ListaEndososAutomovil from "./pages/listaEndososAutomovil/ListaEndososAutomovil";
import ReporteModulosInternos from "./pages/reporteModulosInternos/ReporteModulosInternos";
import ReporteModuloOmNav from "./pages/reporteModuloOmNav/ReporteModuloOmNav";
import ReporteNuevosClientes from "./pages/reporteNuevosClientes/ReporteNuevosClientes";
import Error from "./pages/error/Error";
import ListaCancelacionesAutos from "./pages/listaCancelacionesAutos/ListaCancelacionesAutos";
import CancelacionesAutos from "./pages/cancelacionesAutos/CancelacionesAutos";
import ReporteIncentivos from "./pages/reporteIncentivos/ReporteIncentivos";
import ListaProspectos from "./pages/listaProspectos/ListaProspectos";
import ListaSeguimientos from "./pages/listaSeguimientos/ListaSeguimientos";
import Prospectos from "./pages/prospectos/Prospectos";
import Seguimientos from "./pages/seguimientos/Seguimientos";
import CaratulaAutomovil from "./pages/caratulaAutomovil/CaratulaAutomovil";
import SeguimientosProgramados from "./pages/seguimientosProgramados/SeguimientosProgramados";

class App extends React.Component {

  logout = () => {
    confirmAlert({
      message: "¿Desea salir del sistema?",
      buttons: [
        {
          label: "Continuar",
          onClick: () => {
            localStorage.removeItem("NAid");
            localStorage.removeItem("NAuser");
            localStorage.removeItem("NAmail");
            localStorage.removeItem("NApermisos");
            window.location.reload();
          },
        },
        {
          label: "Cancelar",
        },
      ],
      overlayClassName: "backGroundDialog",
    });
  };

  render() {
    return (
      <div class="wrapper">
        <Router>
          <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
              <a
                href="index3.html"
                className="brand-link"
                style={{ height: "60px" }}
              >
                <img
                  src="../dist/img/logofr1.png"
                  alt="AdminLTE Logo"
                  className="brand-image"
                />
              </a>
              <div className="sidebar">
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    <Link to="/cumpleanios">
                      <a className="nav-link">
                        🎂
                        <p>Cumpleaños</p>
                      </a>
                    </Link>
                    <li className="nav-item has-treeview">
                      <a href="#" className="nav-link">
                        📅
                        <p>Catalogo
                        <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                        <Link to="/listaagentesseguros">
                      <a className="nav-link">
                        👨‍
                        <p>Agentes</p>
                      </a>
                    </Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/listaclientes">
                      <a className="nav-link">
                        🧍
                        <p>Clientes</p>
                      </a>
                    </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/listacancelacionesautos">
                            <a className="nav-link">
                            ❌
                              <p>Cancelaciones autos</p>
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item has-treeview">
                      <a href="#" className="nav-link">
                        🚗
                        <p>
                          Automovil
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/listapolizasautomovil"
                            className="nav-link"
                          >
                            📋
                            <p>Polizas</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/listapagospolizasautomovil"
                            className="nav-link"
                          >
                            🧾
                            <p>Pagos Polizas</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/multicotizador"
                            className="nav-link"
                          >
                            💸
                            <p>Multicotizador</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/compararpolizasautomovil"
                            className="nav-link"
                          >
                            📇
                            <p>Comparar Polizas</p>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item has-treeview">
                      <a href="#" className="nav-link">
                        📬
                        <p>
                          Prospección
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/listaprospectos"
                            className="nav-link"
                          >
                            👨‍
                            <p>Prospectos</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/listaseguimientos"
                            className="nav-link"
                          >
                            ✉️
                            <p>Seguimiento</p>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item has-treeview">
                      <a href="#" className="nav-link">
                        🩺
                        <p>
                          Gastos Medicos
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/listapolizasgmm"
                            className="nav-link"
                          >
                            📋
                            <p>Polizas</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/listapagospolizasgmm"
                            className="nav-link"
                          >
                            🧾
                            <p>Pagos Polizas</p>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item has-treeview">
                      <a href="#" className="nav-link">
                        📁
                        <p>
                          Reportes
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportepagospolizas"
                            className="nav-link"
                          >
                            💰
                            <p>Pagos Polizas Generales</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reporteemisionpolizas"
                            className="nav-link"
                          >
                            📖
                            <p>Emision Polizas Generales</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportepagosagentes"
                            className="nav-link"
                          >
                            💵
                            <p>Pagos Agentes</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportellamadascalidadautomovil"
                            className="nav-link"
                          >
                            📞
                            <p>Llamadas De Calidad</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reporteproduccionagentes"
                            className="nav-link"
                          >
                            🗃️
                            <p>Produccion Por Agente</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportecobranzaautomovil"
                            className="nav-link"
                          >
                            💱
                            <p>Cobranza De Automovil</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reporterenovacionesautomovil"
                            className="nav-link"
                          >
                            📝
                            <p>Renovaciones De Automovil</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportecobranzagmm"
                            className="nav-link"
                          >
                            💱
                            <p>Cobranza De GMM</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reporterenovacionesgmm"
                            className="nav-link"
                          >
                            📝
                            <p>Renovaciones De GMM</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportenuevosclientes"
                            className="nav-link"
                          >
                            💹
                            <p>Nuevos Clientes</p>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item has-treeview">
                      <a href="#" className="nav-link">
                        ✨
                        <p>
                          Especiales
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportemodulosinternos"
                            className="nav-link"
                          >
                            🏬
                            <p>Modulos Internos</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reportemoduloomnav"
                            className="nav-link"
                          >
                            🏬
                            <p>Modulos OmNav</p>
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-treeview ml-2">
                        <li className="nav-item">
                          <Link
                            to="/reporteincentivos"
                            className="nav-link"
                          >
                            🗳️
                            <p>Incentivos Laborales</p>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <a className="nav-link" onClick={() => this.logout()}>
                      🔒
                      <p className="ml-1">Salir</p>
                    </a>
                  </ul>
                </nav>
              </div>
            </aside>
          </div>
          <div className="content-wrapper">
            <Switch>
              <Route path="/listaagentesseguros" component={ListaAgentesSeguros} exact />
              <Route path="/nuevoagenteseguro" component={AgentesSeguros} exact />
              <Route path="/editaragenteseguro/:id" component={AgentesSeguros} exact />
              <Route path="/listaclientes" component={ListaClientes} exact />
              <Route path="/nuevocliente" component={Clientes} exact />
              <Route path="/editarcliente/:id" component={Clientes} exact />
              <Route path="/listacancelacionesautos" component={ListaCancelacionesAutos} exact />
              <Route path="/nuevacancelacionesautos" component={CancelacionesAutos} exact />
              <Route path="/editarcancelacionesautos/:id_cancelacion_auto" component={CancelacionesAutos} exact />
              <Route path="/listapolizasautomovil" component={ListaPolizasAutomovil} exact />
              <Route path="/nuevapolizaautomovil" component={PolizasAutomovil} exact />
              <Route path="/editarpolizaautomovil/:id" component={PolizasAutomovil} exact />
              <Route path="/listapagospolizasautomovil" component={ListaPagosPolizasAutomovil} exact />
              <Route path="/llamadapolizaautomovil/:id" component={LlamadaPolizasAutomovil} exact />
              <Route path="/capturapolizasautomovil" component={CapturaPolizasAutomovil} exact />
              <Route path="/llamadarenovacionautomovil/:id" component={LlamadaRenovacionesAutomovil} exact />
              <Route path="/multicotizador" component={Multicotizador} exact />
              <Route path="/reportepagospolizas" component={ReportePagosPolizas} exact />
              <Route path="/reporteemisionpolizas" component={ReporteEmisionPolizas} exact />
              <Route path="/reporteproduccionagentes" component={ReporteProduccionAgentes} exact />
              <Route path="/cumpleanios" component={Cumpleanios} exact />
              <Route path="/reportepagosagentes" component={ReportePagosAgentes} exact />
              <Route path="/listapolizasgmm" component={ListaPolizasGMM} exact />
              <Route path="/nuevapolizagmm" component={PolizasGMM} exact />
              <Route path="/editarpolizagmm/:id" component={PolizasGMM} exact />
              <Route path="/listapagospolizasgmm" component={ListaPagosPolizasGMM} exact />
              <Route path="/llamadapolizagmm/:id" component={LlamadaPolizasGMM} exact />
              <Route path="/cobrospolizasautomovil" component={CobrosPolizasAutomovil} exact />
              <Route path="/reporteproduccionagentes" component={ReporteProduccionAgentes} exact />
              <Route path="/listallamadascalidad" component={ListaLlamadasCalidad} exact />
              <Route path="/llamadacalidad/:id" component={LlamadaCalidad} exact />
              <Route path="/reportellamadascalidadautomovil" component={ReporteLlamadasCalidadAutomovil} exact />
              <Route path="/compararpolizasautomovil" component={CompararPolizasAutomovil} exact />
              <Route path="/reportecobranzaautomovil" component={ReporteCobranzaAutomovil} exact />
              <Route path="/reporterenovacionesautomovil" component={ReporteRenovacionesAutomovil} exact />
              <Route path="/agentesespeciales/:id" component={AgentesSegurosEspecial} exact />
              <Route path="/reportecobranzagmm" component={ReporteCobranzaGMM} exact />
              <Route path="/reporterenovacionesgmm" component={ReporteRenovacionesGMM} exact />
              <Route path="/endososautomovil/:id" component={EndososAutomovil} exact />
              <Route path="/listaendososautomovil" component={ListaEndososAutomovil} exact />
              <Route path="/reportemodulosinternos" component={ReporteModulosInternos} exact />
              <Route path="/reportemoduloomnav" component={ReporteModuloOmNav} exact />
              <Route path="/reportenuevosclientes" component={ReporteNuevosClientes} exact />
              <Route path="/reporteincentivos" component={ReporteIncentivos} exact />
              <Route path="/listaprospectos" component={ListaProspectos} exact />
              <Route path="/nuevoprospecto" component={Prospectos} exact />
              <Route path="/listaseguimientos" component={ListaSeguimientos} exact />
              <Route path="/editarprospecto/:id" component={Prospectos} exact />
              <Route path="/seguimientos/:id" component={Seguimientos} exact />
              <Route path="/caratulaautomovil" component={CaratulaAutomovil} exact />
              <Route path="/error" component={Error} exact />
              <Route path="/seguimientosprogramados/:id" component={SeguimientosProgramados} exact />
              <Route path="/" exact />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
