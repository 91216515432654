import React from 'react';
import "./capturaPolizasAutomovil.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class CapturaPolizasAutomovil extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      filtro: '',
      loader: true,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10,
      selectedFile: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleSubmit = event => {
    event.preventDefault();
  }

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  subirPolizas() { 
    if(Object.keys(this.state.dataTable).length == 0){
      infoMessage(2, 'Mensaje del sistema', 'No se encontrarón datos para guardar.');
      return false;
    }
    Object.keys(this.state.dataTable).forEach(key => {
      if(this.state.dataTable[key].estado != 'OK'){
        infoMessage(2, 'Mensaje del sistema', 'Todas las polizas deben de estar autorizadas para poder subir el archivo');
        return false;
      }
    });
    this.setState({loader: true});
    axios.get("apiv2/AnalizarPolizasAutomovilController.php/subirPolizasNuevas", {
      params:{
        data:JSON.stringify(this.state.dataTable),
      }
    }).then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data
        });
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      },1000);
    })
    .catch(console.log);
  }

  /*** FILE DATA***/
  onFileChange = event => { 
    // Update the state 
    this.setState({ selectedFile: event.target.files[0] }); 
  }
  
  // On file upload (click the upload button) 
	onFileUpload = () => { 
	  // Create an object of formData 
	  const formData = new FormData(); 
	 
	  // Update the formData object 
    if(this.state.selectedFile == null){
      infoMessage(2, 'Mensaje del sistema', 'Seleccione un archivo para poder continuar.');
      return false;
    }
	  formData.append( 
	    "polizasNuevas", 
	    this.state.selectedFile, 
	    this.state.selectedFile.name 
	  ); 
	 
	  // Request made to the backend api 
	  // Send formData object 
    this.setState({loader: true});
	  axios.post("apiv2/AnalizarPolizasAutomovilController.php/analizarPolizasNuevas", formData).then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data
        });
        if(!result.data.success){
          infoMessage(2, 'Mensaje del sistema', result.data.message);
        }
      },1000);
    })
    .catch(console.log);; 
	}

  fileData = () => { 
      if (this.state.selectedFile) { 
          
        return ( 
          <div> 
            <h2>File Details:</h2> 
            <p>File Name: {this.state.selectedFile.name}</p> 
            <p>File Type: {this.state.selectedFile.type}</p> 
            <p> 
              Last Modified:{" "} 
              {this.state.selectedFile.lastModifiedDate.toDateString()} 
            </p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div> 
            <br /> 
            <h4>Choose before Pressing the Upload button</h4> 
          </div> 
        ); 
      } 
    }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Captura De Polizas</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                  	<form onSubmit={this.handleSubmit}>  
  						        <div> 
  			                <input type="file" onChange={this.onFileChange} /> 
  			                <button className="btn btn-info btn-sm ml-2" onClick={() => this.onFileUpload()}> 
                          Analizar Archivo 
                        </button> 
                        <button className="btn btn-success btn-sm ml-2" onClick={() => this.subirPolizas()}> 
                          Guardar Polizas
                        </button> 
  			              </div> 
                    </form>
                  </div>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Poliza</th>
                          <th>Cliente</th>
                          <th>Telefono</th>
                          <th>Descripcion</th>
                          <th>Agente</th>
                          <th>Tipo</th>
                          <th>Estatus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result) => {
                          return(
                            <tr class="navbar-btn sidebar-toggle" data-toggle="offcanvas" role="button" data-tooltip="tooltip" 
                              title={"[Pago Inicial: "+result.pago_inicial+"][Pago Subsencuenta: "+result.pago_subsecuente+"][Vigencia: "+result.fecha_vigencia+"]"}>
                              <td>{result.poliza}</td>
                              <td>{result.cliente}</td>
                              <td>{result.telefono}</td>
                              <td>{result.descripcion}</td>
                              <td>{result.subagente}</td>
                              <td>{result.tipo_poliza}</td>
                              <td><span className={result.estado == 'OK' ? "badge bg-success" : "badge bg-primary"}>{result.estado}</span></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix Table-Details">
                    <div className="pagination pagination-sm m-0 float-left">
                      <b>Pagina {this.state.currentPage + 1} de {this.state.totalPages}</b>
                    </div>
                    <div className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.initialPage()}>
                          <i className="fa fa-angle-double-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage == 0} 
                          type="button" class="btn btn-link" onClick={() => this.prevPage()}>
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.nextPage()}>
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button disabled={this.state.currentPage + 1 == this.state.totalPages} 
                          type="button" class="btn btn-link" onClick={() => this.lastPage()}>
                          <i className="fa fa-angle-double-right"></i>
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default CapturaPolizasAutomovil;