import React from 'react';
import "./agentesSeguros.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link } from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class AgentesSeguros extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      nombre: '',
      telefono: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);

    if(this.props.match.params.id !== undefined){
      this.setState({loader: true});
      axios.get('/apiv2/AgentesController.php/DetallesAgentes',{
        params:{
          id:this.props.match.params.id
        }
      })
      .then(result => {
        this.setState({
          loader: false,
          nombre: result.data.data[0].nombre,
          telefono: result.data.data[0].telefono,
        });
      })
      .catch(console.log);      
    }
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  saveProvider(){
    if(this.state.nombre == ''){
      infoMessage(2, 'Error de captura','Capture el nombre para poder continuar.');
      return false;
    }
    if(this.state.telefono == ''){
      infoMessage(2, 'Error de captura','Capture el telefono para poder continuar.');
      return false;
    }
    
    if(this.state.telefono.length < 10){
      infoMessage(2, 'Error de captura','El telefono debe contener al menos 10 digitos.');
      return false;
    }
    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/apiv2/AgentesController.php/Agentes',{
        id:this.props.match.params.id,
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        domicilio: this.state.domicilio,
        idusuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/apiv2/AgentesController.php/Agentes',
      {
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        domicilio: this.state.domicilio,
        idusuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-6">
                        <label className="control-label">Nombre</label>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" 
                        maxLength="60" value={this.state.nombre} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-6">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" placeholder="Telefono" name="telefono"
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} ></input>
                      </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listaagentesseguros">
                        <button type="button" 
                          className="btn btn-danger btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.saveProvider()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default AgentesSeguros;