import React from 'react';
import "./endososAutomovil.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';

class EndososAutomovil extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      id_tipo_endoso:1,
      ddTiposEndosos:[
        {id: 1, text: 'Tipo A', value:1},
        {id: 2, text: 'Tipo D', value:2}
      ],
      fecha_inicio:new Date(),
      endoso:'',
      comision_inicial:0,
      comision_subsecuente:0,
      pago_inicial:0,
      pago_subsecuente:0,
      ddTiposPolizas: [],
      pago_inicial_poliza: 0,
      pago_subsecuente_poliza: 0,
      comision_poliza:0,
      id_tipo_poliza:1,
      descripcion:'',
    };

    this.tiposPolizasRef = React.createRef();
    this.tiposEndososRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.setState({loader: true});  
    axios.get('/apiv2/CatalogosController.php/TiposPolizas')
    .then(result => {
      this.setState({
        ddTiposPolizas: result.data.data,
        polizaSelected: result.data.data[0].value,
        id_tipo_poliza: result.data.data[0].value,
      });

      axios.get('/apiv2/PolizasAutomovilController.php/DetallesPolizaAutomovil',{
        params:{
          id:this.props.match.params.id,
        }
      })
      .then(result => {
        this.setState({
          pago_inicial_poliza: result.data.data[0].pago_inicial,
          pago_subsecuente_poliza: result.data.data[0].pago_subsecuente,
          comision_poliza: result.data.data[0].comision,
          id_tipo_poliza:parseInt(result.data.data[0].id_tipo_poliza),
          loader:false
        });
        this.tiposPolizasRef.current.value = parseInt(result.data.data[0].id_tipo_poliza);
      }).catch(console.log);  
    }).catch(console.log);

    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    this.setState({fecha_inicio: event});
  }

  guardarEndoso(){
    if(this.state.endoso == ''){
      infoMessage(2, 'Error de captura','Capture el endoso para poder continuar.');
      return false;
    }
    if(this.state.fecha_inicio == null){
      infoMessage(2, 'Error de captura','Capture la fecha de inicio para poder continuar.');
      return false;
    }
    if(this.state.pago_inicial === ''){
      console.log(this.state.pago_inicial);
      return false;
      infoMessage(2, 'Error de captura','Capture el pago inicial para poder continuar.');
      return false;
    }
    if(this.state.pago_subsecuente === ''){
      infoMessage(2, 'Error de captura','Capture el pago subsecuente para poder continuar.');
      return false;
    }
    if(this.state.comision_inicial === ''){
      infoMessage(2, 'Error de captura','Capture la comision inicial para poder continuar.');
      return false;
    }
    if(this.state.comision_subsecuente === ''){
      infoMessage(2, 'Error de captura','Capture la comision subsecuente para poder continuar.');
      return false;
    }
    if(this.state.descripcion === ''){
      infoMessage(2, 'Error de captura','Capture la descripcion para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    axios.post('/apiv2/EndososAutomovilController.php/EndososAutomovil',
    {
      id_poliza: this.props.match.params.id,
      id_tipo_endoso: this.state.id_tipo_endoso,
      endoso: this.state.endoso,
      pago_inicial: this.state.pago_inicial,
      pago_subsecuente: this.state.pago_subsecuente,
      comision_inicial: this.state.comision_inicial,
      comision_subsecuente: this.state.comision_subsecuente,
      fecha_inicio: this.state.fecha_inicio,
      descripcion: this.state.descripcion,
      cantidad_recibos: this.state.cantidad_recibos,
      idusuario:localStorage.getItem("NAid"),          
      pago_inicial_poliza: this.state.pago_inicial_poliza,
      pago_subsecuente_poliza: this.state.pago_subsecuente_poliza,
      comision_poliza: this.state.comision_poliza,
      id_tipo_poliza: this.state.id_tipo_poliza
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
    })
    .catch(console.log);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Endoso</label>
                        <input type="text" className="form-control" name="endoso" 
                        maxLength="60" value={this.state.endoso} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_endoso} name="id_tipo_endoso" ref={this.tiposEndososRef}>
                          {this.state.ddTiposEndosos.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Fecha De Inicio</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_inicio}/>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-3">
                        <label className="control-label">Pago Inicial</label>
                        <input type="number" className="form-control" name="pago_inicial" 
                        maxLength="60" value={this.state.pago_inicial} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Pago Subsecuente</label>
                        <input type="number" className="form-control" name="pago_subsecuente" 
                        maxLength="60" value={this.state.pago_subsecuente} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Comision Inicial</label>
                        <input type="number" className="form-control" name="comision_inicial" 
                        maxLength="60" value={this.state.comision_inicial} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Comision Subsecuente</label>
                        <input type="number" className="form-control" name="comision_subsecuente" 
                        maxLength="60" value={this.state.comision_subsecuente} onChange={this.handleInputChange}></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-12">
                        <label for="inputAddress">Descripcion</label>
                        <textarea type="text" className="form-control" name="descripcion"
                          value={this.state.descripcion} onChange={this.handleInputChange} ></textarea>
                      </div>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>Detalles De Poliza</b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-3">
                        <label className="control-label">Forma De Pago</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef}>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Pago Inicial</label>
                        <input type="number" className="form-control" name="pago_inicial_poliza" 
                        maxLength="60" value={this.state.pago_inicial_poliza} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Pago Subsecuente</label>
                        <input type="number" className="form-control" name="pago_subsecuente_poliza" 
                        maxLength="60" value={this.state.pago_subsecuente_poliza} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Comision Por Recibo</label>
                        <input type="number" className="form-control" name="comision_poliza" 
                        maxLength="60" value={this.state.comision_poliza} onChange={this.handleInputChange}></input>
                      </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listapolizasautomovil">
                        <button type="button" 
                          className="btn btn-dark btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarEndoso()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default EndososAutomovil;