import React from 'react';
import "./polizasAutomovil.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';

class PolizasAutomovil extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      poliza: '',
      detalles: '',
      id_cliente: '',
      id_tipo_poliza: '',
      id_agente: '',
      id_compania_automovil: '',
      id_covertura_automovil: '',
      periodo_gracia_inicial: 0,
      periodo_gracia_subsecuente: 0,
      pago_inicial: 0,
      pago_subsecuente: 0,
      comision:0,
      serie: '',
      cobro_administrativo:0,
      fecha_emision: new Date(),
      ddTiposPolizas: [],
      ddAgentes: [],
      ddClientes: [],
      ddCompania: [],
      ddCoberturas: [],
      calendario:[],
      showCalenda: false,
      poliza_precargada:'',
      poliza_precargada_text:''
    };

    this.tiposPolizasRef = React.createRef();
    this.agentesRef = React.createRef();
    this.clientesRef = React.createRef();
    this.companiasRef = React.createRef();
    this.coberturasRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.setState({loader: true});
    axios.get('/apiv2/CatalogosController.php/Agentes')
    .then(result => {
      this.setState({
        ddAgentes: result.data.data,
        agenteSelected: result.data.data[0].value,
        id_agente: result.data.data[0].value
      });
      this.agentesRef.current.value = parseInt(result.data.data[0].value); 

      axios.get('/apiv2/CatalogosController.php/Clientes')
      .then(result => {
        this.setState({
          ddClientes: result.data.data,
          clienteSelected: result.data.data[0].value,
          id_cliente: result.data.data[0].value
        });
        this.clientesRef.current.value = parseInt(result.data.data[0].value); 

        axios.get('/apiv2/CatalogosController.php/CompaniasAutomovil')
        .then(result => {
          this.setState({
            ddCompania: result.data.data,
            companiaSelected: result.data.data[0].value,
            id_compania_automovil: result.data.data[0].value
          });
          this.companiasRef.current.value = parseInt(result.data.data[0].value); 


          axios.get('/apiv2/CatalogosController.php/CoberturasAutomovil')
          .then(result => {
            this.setState({
              ddCoberturas: result.data.data,
              coberturaSelected: result.data.data[0].value,
              id_cobertura_automovil: result.data.data[0].value
            });
            this.coberturasRef.current.value = parseInt(result.data.data[0].value); 

            axios.get('/apiv2/CatalogosController.php/TiposPolizas')
            .then(result => {
              this.setState({
                loader: false,
                ddTiposPolizas: result.data.data,
                polizaSelected: result.data.data[0].value,
                id_tipo_poliza: result.data.data[0].value
              });
              this.tiposPolizasRef.current.value = parseInt(result.data.data[0].value); 

              if(this.props.match.params.id !== undefined){
                this.setState({loader: true});
                axios.get('/apiv2/PolizasAutomovilController.php/DetallesPolizaAutomovil',{
                  params:{
                    id:this.props.match.params.id,
                  }
                })
                .then(result => {
                  var datee = new Date(result.data.data[0].fecha_emision);
                  console.log(result)
                  this.setState({
                    
                    poliza: result.data.data[0].poliza,
                    detalles: result.data.data[0].descripcion,
                    cliente: result.data.data[0].cliente,
                    telefono: result.data.data[0].telefono,
                    correo: result.data.data[0].correo,
                    serie: result.data.data[0].serie,
                    pago_inicial: result.data.data[0].pago_inicial,
                    pago_subsecuente: result.data.data[0].pago_subsecuente,
                    fecha_emision: new Date(datee.setDate(datee.getDate() + 1)),
                    id_compania_automovil:result.data.data[0].id_compania_automovil,
                    id_tipo_poliza: result.data.data[0].id_tipo_poliza,
                    id_cliente: result.data.data[0].id_cliente,
                    id_agente: result.data.data[0].id_agente,
                    comision: result.data.data[0].comision,
                    cobro_administrativo: result.data.data[0].cobro_administrativo,
                    id_cobertura_automovil: result.data.data[0].id_cobertura_automovil,
                    periodo_gracia_inicial: result.data.data[0].periodo_gracia_inicial,
                    periodo_gracia_subsecuente: result.data.data[0].periodo_gracia_subsecuente,
                  }
                  );

                  
                  this.tiposPolizasRef.current.value = parseInt(result.data.data[0].id_tipo_poliza);
                  this.agentesRef.current.value = parseInt(result.data.data[0].id_agente);
                  this.clientesRef.current.value = parseInt(result.data.data[0].id_cliente);
                  this.companiasRef.current.value = parseInt(result.data.data[0].id_compania_automovil);
                  this.coberturasRef.current.value = parseInt(result.data.data[0].id_cobertura_automovil);

                  this.setState({loader: true});
  	            axios.get('/apiv2/PagoPolizasAutomovilController.php/DetallesPolizaAutomovil',{
  	              params:{
  	                id:this.props.match.params.id,
  	              }
  	            })
  	            .then(result => {
  	              this.setState({
  	                loader: false,
  	                showCalendar:true,
  	                calendario:result.data.data.calendario
  	              });
  	          	}).catch(console.log); 
                }).catch(console.log);      
              }
            }).catch(console.log);
          }).catch(console.log);
        }).catch(console.log);
      }).catch(console.log);
    }).catch(console.log);
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleKeyDown(event){
    if(event.keyCode == 13){
      this.setState({loader: true});
      axios.get('/apiv2/PolizasAutomovilController.php/DetallesPolizaAutomovilPrecarga',{
        params:{
          poliza:this.state.poliza_precargada,
        }
      })
      .then(result => {

        if(result.data.success){

          var datee = new Date(result.data.data[0].fecha_emision);

          this.setState({
            detalles: result.data.data[0].descripcion,
            cliente: result.data.data[0].cliente,
            telefono: result.data.data[0].telefono,
            correo: result.data.data[0].correo,
            serie: result.data.data[0].serie,
            id_compania_automovil:result.data.data[0].id_compania_automovil,
            id_tipo_poliza: result.data.data[0].id_tipo_poliza,
            id_cliente: result.data.data[0].id_cliente,
            id_agente: result.data.data[0].id_agente,
            id_cobertura_automovil: result.data.data[0].id_cobertura_automovil,
            periodo_gracia_inicial: result.data.data[0].periodo_gracia_inicial,
            periodo_gracia_subsecuente: result.data.data[0].periodo_gracia_subsecuente,
            poliza_precargada_text:this.state.poliza_precargada
          });

          this.tiposPolizasRef.current.value = parseInt(result.data.data[0].id_tipo_poliza);
          this.agentesRef.current.value = parseInt(result.data.data[0].id_agente);
          this.clientesRef.current.value = parseInt(result.data.data[0].id_cliente);
          this.companiasRef.current.value = parseInt(result.data.data[0].id_compania_automovil);
          this.coberturasRef.current.value = parseInt(result.data.data[0].id_cobertura_automovil);

          this.setState({loader: false});
        }else{
          this.setState({
            loader: false,
            poliza_precargada_text:result.data.message
          });
        }
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(name == "id_compania_automovil"){
      this.setState({
        periodo_gracia_inicial: event.target.selectedOptions[0].dataset.inicial,
        periodo_gracia_subsecuente: event.target.selectedOptions[0].dataset.subsecuente
      });
    }
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Date]") {
      if (isNaN(event)) {
      } else {
        this.setState({fecha_emision: event});
      }
    } else {
    }
  }

  guardarPoliza(){
    if(this.state.poliza == ''){
      infoMessage(2, 'Error de captura','Capture la poliza para poder continuar.');
      return false;
    }
    if(this.state.cliente == ''){
      infoMessage(2, 'Error de captura','Capture el cliente para poder continuar.');
      return false;
    }
    if(this.state.telefono == ''){
      infoMessage(2, 'Error de captura','Capture el telefono para poder continuar.');
      return false;
    }
    if(this.state.fecha_emision == ''){
      infoMessage(2, 'Error de captura','Capture la fecha de emision para poder continuar.');
      return false;
    }
    if(this.state.id_cliente == ''){
      infoMessage(2, 'Error de captura','Capture el cliente para poder continuar.');
      return false;
    }
    if(this.state.id_agente == ''){
      infoMessage(2, 'Error de captura','Capture el agente para poder continuar.');
      return false;
    }
    if(this.state.id_tipo_poliza == ''){
      infoMessage(2, 'Error de captura','Capture el tipo de poliza para poder continuar.');
      return false;
    }
    if(this.state.id_cobertura_automovil == ''){
      infoMessage(2, 'Error de captura','Capture la cobertura para poder continuar.');
      return false;
    }
    if(this.state.periodo_gracia_inicial == ''){
      infoMessage(2, 'Error de captura','Capture el periodo de gracia inicial para poder continuar.');
      return false;
    }
    if(this.state.periodo_gracia_subsecuente == ''){
      infoMessage(2, 'Error de captura','Capture el periodo de gracia subsecuente para poder continuar.');
      return false;
    }

    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/apiv2/PolizasAutomovilController.php/PolizasAutomovil',{
        id:this.props.match.params.id,
        poliza: this.state.poliza,
        cliente: this.state.cliente, 
        telefono: this.state.telefono, 
        correo: this.state.correo, 
        pago_inicial: this.state.pago_inicial, 
        pago_subsecuente: this.state.pago_subsecuente, 
        fecha_emision: new Date(this.state.fecha_emision.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        id_agente: this.state.id_agente, 
        id_cliente: this.state.id_cliente, 
        id_tipo_poliza: this.state.id_tipo_poliza, 
        id_compania_automovil: this.state.id_compania_automovil, 
        serie: this.state.serie, 
        cobro_administrativo: this.state.cobro_administrativo, 
        detalles: this.state.detalles,
        comision: this.state.comision,
        id_cobertura_automovil: this.state.id_cobertura_automovil,
        periodo_gracia_inicial: this.state.periodo_gracia_inicial,
        periodo_gracia_subsecuente: this.state.periodo_gracia_subsecuente,
        idusuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/apiv2/PolizasAutomovilController.php/PolizasAutomovil',
      {
        poliza: this.state.poliza,
        cliente: this.state.cliente, 
        telefono: this.state.telefono, 
        correo: this.state.correo, 
        pago_inicial: this.state.pago_inicial, 
        pago_subsecuente: this.state.pago_subsecuente, 
        fecha_emision: new Date(this.state.fecha_emision.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        id_agente: this.state.id_agente, 
        id_cliente: this.state.id_cliente, 
        id_tipo_poliza: this.state.id_tipo_poliza, 
        id_compania_automovil: this.state.id_compania_automovil,
        serie: this.state.serie,  
        cobro_administrativo: this.state.cobro_administrativo,  
        detalles: this.state.detalles,
        comision: this.state.comision,
        id_cobertura_automovil: this.state.id_cobertura_automovil,
        periodo_gracia_inicial: this.state.periodo_gracia_inicial,
        periodo_gracia_subsecuente: this.state.periodo_gracia_subsecuente,
        idusuario:localStorage.getItem("NAid")
      })
      .then(result => {
        
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      {this.props.match.params.id == undefined &&
                        <div className="form-group required col-md-3">
                          <label className="control-label">Precargar Poliza:{this.state.poliza_precargada_text}</label>
                          <input type="text" className="form-control" name="poliza_precargada" onKeyDown={this.handleKeyDown}
                          maxLength="60" value={this.state.poliza_precargada} onChange={this.handleInputChange} ></input>
                        </div>
                      }
                      <div className="form-group required col-md-3">
                        <label className="control-label">Poliza</label>
                        <input type="text" className="form-control" name="poliza" 
                        maxLength="60" value={this.state.poliza} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Agente</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_agente} name="id_agente" ref={this.agentesRef}>
                          {this.state.ddAgentes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Cliente</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_cliente} name="id_cliente" ref={this.clientesRef}>
                          {this.state.ddClientes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-3">
                        <label className="control-label">Compañia</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_compania_automovil} name="id_compania_automovil" ref={this.companiasRef}>
                          {this.state.ddCompania.map((result) => {
                            return(
                              <option data-inicial={result.periodo_gracia_inicial} data-subsecuente={result.periodo_gracia_subsecuente} value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Perido Gracia Inicial</label>
                        <input type="number" className="form-control" name="periodo_gracia_inicial" 
                        maxLength="60" value={this.state.periodo_gracia_inicial} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Perido Gracia Subsecuente</label>
                        <input type="number" className="form-control" name="periodo_gracia_subsecuente" 
                        maxLength="60" value={this.state.periodo_gracia_subsecuente} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef}>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label className="control-label">Pago Inicial</label>
                        <input type="number" className="form-control" name="pago_inicial" 
                        maxLength="60" value={this.state.pago_inicial} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group col-md-3">
                        <label className="control-label">Pago Subsencuente</label>
                        <input type="number" className="form-control" name="pago_subsecuente" 
                        maxLength="60" value={this.state.pago_subsecuente} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group col-md-3">
                        <label className="control-label">Comision Por Recibo</label>
                        <input type="number" className="form-control" name="comision" 
                        maxLength="60" value={this.state.comision} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group col-md-3">
                        <label className="control-label">Cobro Administrativo</label>
                        <input type="number" className="form-control" name="cobro_administrativo" 
                        maxLength="60" value={this.state.cobro_administrativo} onChange={this.handleInputChange}></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Serie</label>
                        <input type="text" className="form-control" name="serie" 
                        maxLength="60" value={this.state.serie} onChange={this.handleInputChange} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Cobertura</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_cobertura_automovil} name="id_cobertura_automovil" ref={this.coberturasRef}>
                          {this.state.ddCoberturas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Inicio De Vigencia</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_emision}/>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} ></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listapolizasautomovil">
                        <button type="button" 
                          className="btn btn-dark btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-primary btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.guardarPoliza()} >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                  {this.state.showCalendar == true &&
	                  <div className="wrapper" style={{paddingTop:"10px"}}>
    				          <div className="container-fluid">
    				            <div className="row">
    				              <div className="col-12">
    				                <div className="card">
    				                  <div className="card-header">
    				                    <h3 className="card-title"><b>Ultimos Pagos</b></h3>
    				                  </div>
    				                  <div className="card-body">
    				                    <ul className="pagination pagination-month justify-content-center">
    				                      <li className="page-item"><a className="page-link">«</a></li>
    				                      {this.state.calendario.map((result) => {
    				                        return(
    				                          <li className={"page-item " + (result.pagado ? 'active' : '')}>
    				                              <a className="page-link">
                                            <p className="page-year">{result.rangoPrincipal}</p>
                                            <p className="page-year">{result.usuario}</p>
                                            <p className="page-year">{result.fecha}</p>
                                            <p className="page-year">$ {result.pago}</p>
    				                              </a>
    				                          </li>
    				                        )
    				                      })}
    				                      <li className="page-item"><a className="page-link">»</a></li>
    				                    </ul>
    				                  </div>
    				                </div>
    				              </div>
    				            </div>
    				          </div>
				            </div>
			  		      }
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default PolizasAutomovil;