import React from 'react';
import "./caratulaAutomovil.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class CaratulaAutomovil extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      filtro: '',
      loader: false,
      currentPage: 0,
      totalPages: 1,
      limitPage: 10,
      polizasEmitidas:'',
      polizasPendientes:'',
      file:''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDownloadFile = this.handleDownloadFile.bind(this);

    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  checarPolizas = () => {
    this.setState({loader: true});
    axios.post('/apiv2/CaratulaAutomovilController.php/Caratula',
    {
      polizas:this.state.polizasEmitidas
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          polizasPendientes: result.data.data
        });
      },1000);
    })
    .catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleFileChange(event) {
    if (event.target.files) {
      if(event.target.files[0].type == 'application/pdf'){
        this.setState({file:event.target.files[0]});
      }else{
        infoMessage(0, 'Mensaje del sistema', 'Solo se aceptan archivos tipo PDF');
      }
    }
  }

  handleDownloadFile(type){
    let formData = new FormData();
    formData.append("pdffile", this.state.file); 
    if(type == 'cotizacion'){
      axios.post('/apiv2/CaratulasAutomovilController.php/Caratulas',formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/pdf'
        }
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Creador De Caratulas</h3>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="form-group" style={{padding:"10px"}}>
                        <div className="mr-2">
                         <h4>Sube el archivo</h4>
                         <input type="file" style={{width:"600px"}} accept="application/pdf" onChange={this.handleFileChange}></input>
                         <h4 style={{width: "800px",marginTop:"10px"}}>Descargalo con la caratula que necesites</h4>
                         <button                             
                            className="btn btn-success btn-block btn-sm" 
                            style={{width: "250px",marginTop:"10px"}} 
                            onClick={() => this.handleDownloadFile('cotizacion')}>Cotización
                          </button>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default CaratulaAutomovil;