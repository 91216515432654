import React from 'react';
import "./llamadaPolizasAutomovil.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class LlamadaPolizasAutomovil extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      poliza: '',
      detalles: '',
      cliente: '',
      telefono: '',
      correo: '',
      id_tipo_poliza: '',
      id_agente: '',
      fecha_emision:'',
      pago_inicial:'',
      pago_subsecuente:'',
      comision:0,
      fecha_lista:'',
      ganancia_bruta:0,
      gastos_administrativos:0,
      ddResultadoLlamada: [],
      historialLlamadas: [],
      id_tipo_llamada:'',
      comentarioLlamada:'',
      ddTiposPolizas: [],
      ddAgentes: [],
      calendario:[],
      endosos:[],
    };

    this.tiposPolizasRef = React.createRef();
    this.agentesRef = React.createRef();
    this.tiposLlamadasRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.reloadPage();
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  reloadPage(){
    this.setState({loader: true});
    axios.get('/apiv2/CatalogosController.php/TiposLlamadas')
    .then(result => {
      this.setState({
        ddResultadoLlamada: result.data.data,
        llamadaSelected: result.data.data[0].value,
        id_tipo_llamada: result.data.data[0].value
      });
      this.tiposLlamadasRef.current.value = parseInt(result.data.data[0].value); 
      axios.get('/apiv2/CatalogosController.php/Agentes')
      .then(result => {
        this.setState({
          ddAgentes: result.data.data,
          agenteSelected: result.data.data[0].value,
          id_agente: result.data.data[0].value
        });
        this.agentesRef.current.value = parseInt(result.data.data[0].value); 

      	axios.get('/apiv2/PagoPolizasAutomovilController.php/Endosos',{
          params:{
            id:this.props.match.params.id,
          }
        })
      	.then(result => {
      	    this.setState({
      	      endosos:result.data.data
      	    });

	        axios.get('/apiv2/CatalogosController.php/TiposPolizas')
	        .then(result => {
	          this.setState({
	            loader: false,
	            ddTiposPolizas: result.data.data,
	            polizaSelected: result.data.data[0].value,
	            id_tipo_poliza: result.data.data[0].value
	          });
	          this.tiposPolizasRef.current.value = parseInt(result.data.data[0].value); 

	          if(this.props.match.params.id !== undefined){
	            this.setState({loader: true});
	            axios.get('/apiv2/PagoPolizasAutomovilController.php/DetallesPolizaAutomovil',{
	              params:{
	                id:this.props.match.params.id,
	              }
	            })
	            .then(result => {
                var datee = new Date(result.data.data.datosCliente[0].fecha_emision);

	              this.setState({
	                loader: false,
	                poliza: result.data.data.datosCliente[0].poliza,
	                detalles: result.data.data.datosCliente[0].descripcion,
	                cliente: result.data.data.datosCliente[0].cliente,
	                telefono: result.data.data.datosCliente[0].telefono,
	                correo: result.data.data.datosCliente[0].correo,
	                pago_inicial: result.data.data.datosCliente[0].pago_inicial,
	                pago_subsecuente: result.data.data.datosCliente[0].pago_subsecuente,
	                fecha_emision: new Date(datee.setDate(datee.getDate() + 1)),
	                id_tipo_poliza: result.data.data.datosCliente[0].id_tipo_poliza,
	                id_agente: result.data.data.datosCliente[0].id_agente,
	                ganancia_bruta: result.data.data.datosCliente[0].comision,
	                gastos_administrativos: result.data.data.datosCliente[0].cobro_administrativo,
	                calendario:result.data.data.calendario
	              });

	              this.tiposPolizasRef.current.value = parseInt(result.data.data.datosCliente[0].id_tipo_poliza);
	              this.agentesRef.current.value = parseInt(result.data.data.datosCliente[0].id_agente);
	            })
	            .catch(console.log);      
	          }

	        }).catch(console.log);
	    }).catch(console.log);

      }).catch(console.log);
    }).catch(console.log);

    axios.get('/apiv2/PagoPolizasAutomovilController.php/HistorialLlamadas',{
      params:{
        id:this.props.match.params.id,
      }
    }).then(result => {
      this.setState({
        historialLlamadas:result.data.data
      });
    }).catch(console.log);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Date]") {
      if (isNaN(event)) {
      } else {
        this.setState({fecha_lista: event});
      }
    } else {
    }
  }

  saveModal = (identificador) => {
    if(identificador == 1){
      console.log(this.tiposPolizasRef.current.value);
      let mes = '';
      switch (this.tiposPolizasRef.current.value) {
        case '1':
          mes = 12;
          break;
        case '2':
          mes = 1;
          break;
        case '3':
          mes = 3;
          break;
        case '4':
          mes = 6;
          break;
      }
      confirmAlert({
        message: 'Desea agregar pago?[Se agregarian $'+this.state.ganancia_bruta+' como ganancia del pago].',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.agregarPago()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }else if(identificador == 2){
      confirmAlert({
        message: 'El cliente no aparece en la lista hasta la fecha seleccionada¿Desea continuar?.',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.cambiarFechaLista()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }else if(identificador == 3){
      confirmAlert({
        message: 'Desea agregar la llamada?.',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.guardarLlamada()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }else if(identificador == 4){
      confirmAlert({
        message: 'Desea agregar un pago al endoso?.',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.guardarPagoEndoso()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }
  }

  guardarLlamada(){
    this.setState({loader: true});
    axios.post('/apiv2/PagoPolizasAutomovilController.php/AgregarLlamada',
    {
      id: this.props.match.params.id, 
      comentario: this.state.comentarioLlamada, 
      tipo: this.state.id_tipo_llamada
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        this.reloadPage();
      },1200);
    })
    .catch(console.log);
  }

  agregarPago(){
    this.setState({loader: true});
    axios.post('/apiv2/PagoPolizasAutomovilController.php/AgregarPago',
    {
      id: this.props.match.params.id,
      id_tipo_poliza: this.state.id_tipo_poliza,
      ganancia_bruta: this.state.ganancia_bruta,
      gastos_administrativos: this.state.gastos_administrativos,
      idusuario:localStorage.getItem("NAid")
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      if(result.data.success){
        setTimeout(() => {
          this.reloadPage();
        },1200);
      }
    })
    .catch(console.log);
  }

  cambiarFechaLista(){
    this.setState({loader: true});
    axios.post('/apiv2/PagoPolizasAutomovilController.php/CambiarFechaLista',
    {
      id: this.props.match.params.id,
      fecha: this.state.fecha_lista
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        this.reloadPage();
      },1200);
    })
    .catch(console.log);
  }

  guardarPagoEndoso(result){
    this.setState({loader: true});
    axios.post('/apiv2/PagoPolizasAutomovilController.php/AgregarPagoEndoso',
    {
      id: result.id_endoso_automovil,
      pago_inicial: result.pago_inicial,
      pago_subsecuente: result.pago_subsecuente,
      comision_inicial: result.comision_inicial,
      comision_subsecuente: result.comision_subsecuente,
      cantidad_recibos: result.cantidad_recibos,
      idusuario:localStorage.getItem("NAid")
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      if(result.data.success){
        setTimeout(() => {
          this.reloadPage();
        },1200);
      }
    })
    .catch(console.log);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"><b>Ultimos Pagos</b></h3>
                  </div>
                  <div className="card-body">
                    <ul className="pagination pagination-month justify-content-center">
                      <li className="page-item"><a className="page-link">«</a></li>
                      {this.state.calendario.map((result) => {
                        return(
                          <li className={"page-item " + (result.pagado ? 'active' : '')}>
                              <a className="page-link">
                                  <p className="page-year">{result.rangoPrincipal}</p>
                                  <p className="page-year">{result.usuario}</p>
                                  <p className="page-year">{result.fecha}</p>
                                  <p className="page-year">$ {result.pago}</p>
                              </a>
                          </li>
                        )
                      })}
                      <li className="page-item"><a className="page-link">»</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"><b>Endosos</b></h3>
                  </div>
                  <div className="card-body">
                  	{this.state.endosos.map((result) => {
                        return(
	                  		<div className="form-row">
		                      <div className="form-group col-md-2">
		                        <label className="control-label">Endoso</label>
		                        <p>{result.endoso_texto}</p>
		                      </div>
		                      <div className="form-group col-md-2">
		                        <label className="control-label">Pago Inicial</label>
		                        <p>{result.pago_inicial}</p>
		                      </div>
		                      <div className="form-group col-md-2">
		                        <label className="control-label">Pago SubSecuente</label>
		                        <p>{result.pago_subsecuente}</p>
		                      </div>
		                      <div className="form-group col-md-2">
		                        <label className="control-label">Comision Inicial</label>
		                        <p>{result.comision_inicial}</p>
		                      </div>
		                      <div className="form-group col-md-2">
		                        <label className="control-label">Comision SubSecuente</label>
		                        <p>{result.comision_subsecuente}</p>
		                      </div>
                          <div className="form-group col-md-2">
                            <button type="button" 
                              className="btn btn-block bg-gradient-success mt-4" 
                              onClick={() => this.guardarPagoEndoso(result)} >
                              <i className="fa fa-plus-circle"></i> Agregar Pago Endoso
                            </button>
                          </div>
                          <div className="card-body">
                            <ul className="pagination pagination-month justify-content-center">
                              <li className="page-item"><a className="page-link">«</a></li>
                              {result.calendario.map((result2) => {
                                return(
                                  <li className={"page-item " + (result2.pagado ? 'active' : '')}>
                                      <a className="page-link">
                                          <p className="page-year">{result2.rangoPrincipal}</p>
                                          <p className="page-year">{result2.usuario}</p>
                                          <p className="page-year">{result2.fecha}</p>
                                          <p className="page-year">$ {result2.pago}</p>
                                      </a>
                                  </li>
                                )
                              })}
                              <li className="page-item"><a className="page-link">»</a></li>
                            </ul>
                          </div>
		                      <div className="form-group col-md-12">
		                      	<hr></hr>
		                      </div> 
	                    	</div>
	                      )
	                  })}	
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-8">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>INFORMACION DEL CLIENTE</b>
                      </h3>
                      <div className="form-row justify-content-end">
                        <Link to="/listapagospolizasautomovil">
                          <button type="button" 
                            className="btn-sm btn-danger btn-block">
                            <i className="fa fa-arrow-circle-left"></i> Volver
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label className="control-label">Poliza</label>
                        <input type="text" className="form-control" name="poliza" 
                        maxLength="60" value={this.state.poliza} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-5">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-5">
                        <label className="control-label">Agente</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_agente} name="id_agente" ref={this.agentesRef} disabled>
                          {this.state.ddAgentes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" name="telefono" 
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Correo</label>
                        <input type="text" className="form-control" name="correo" 
                        maxLength="60" value={this.state.correo} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Inicio De Vigencia</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_emision} disabled={true}/>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef} disabled>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Pago Inicial</label>
                        <input type="number" className="form-control" name="pago_inicial" 
                        maxLength="10" value={this.state.pago_inicial} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Pago Subsecuente</label>
                        <input type="number" className="form-control" name="pago_subsecuente" 
                        maxLength="10" value={this.state.pago_subsecuente} onChange={this.handleInputChange} disabled></input>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} disabled></textarea>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-4">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>OPCIONES DE LISTA</b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="control-label">Comision</label>
                        <input type="text" className="form-control" name="ganancia_bruta" 
                        maxLength="60" value={this.state.ganancia_bruta} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label">Cobro Administrativo</label>
                        <input type="text" className="form-control" name="gastos_administrativos" 
                        maxLength="60" value={this.state.gastos_administrativos} onChange={this.handleInputChange}></input>
                      </div>
                      <div className="form-group col-md-12">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(1)} >
                          <i className="fa fa-plus-circle"></i> Agregar Pago Poliza
                        </button>
                      </div>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="control-label">Ocultar De Lista</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_lista} />
                      </div>
                      <div className="form-group col-md-4">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(2)} >
                          <i className="fa fa-calendar-week"></i> Actualizar
                        </button>
                      </div>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="control-label">Resultado</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_llamada} name="id_tipo_llamada" ref={this.tiposLlamadasRef}>
                          {this.state.ddResultadoLlamada.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(3)} >
                          <i className="fa fa-phone"></i> Agregar
                        </button>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input type="text" className="form-control" name="comentarioLlamada" 
                          maxLength="60" value={this.state.comentarioLlamada} onChange={this.handleInputChange}></input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <div class="card-header">
                    <h3 class="card-title">
                      <b>Ultimas 5 Llamadas</b>
                    </h3>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Resultado</th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.historialLlamadas.map((result) => {
                        return(
                          <tr>
                            <td>{result.fecha_registro}</td>
                            <td>{result.nombre}</td>
                            <td>{result.descripcion}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default LlamadaPolizasAutomovil;