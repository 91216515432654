import React from 'react';
import "./llamadaRenovacionesAutomovil.css";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DatePicker from 'react-date-picker';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class LlamadaRenovacionesAutomovil extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      poliza: '',
      detalles: '',
      cliente: '',
      telefono: '',
      correo: '',
      id_tipo_poliza: '',
      id_agente: '',
      fecha_emision:'',
      fecha_lista:'',
      ganancia_bruta:0,
      ddResultadoLlamada: [],
      historialLlamadas: [],
      id_tipo_llamada:'',
      comentarioLlamada:'',
      ddTiposPolizas: [],
      ddAgentes: [],
      calendario:[]
    };

    this.tiposPolizasRef = React.createRef();
    this.agentesRef = React.createRef();
    this.tiposLlamadasRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.setState({loader: true});
    axios.get('/apiv2/CatalogosController.php/TiposLlamadas')
    .then(result => {
      this.setState({
        loader: false,
        ddResultadoLlamada: result.data.data,
        llamadaSelected: result.data.data[0].value,
        id_tipo_llamada: result.data.data[0].value
      });
      this.tiposLlamadasRef.current.value = parseInt(result.data.data[0].value); 
      axios.get('/apiv2/CatalogosController.php/Agentes')
      .then(result => {
        this.setState({
          loader: false,
          ddAgentes: result.data.data,
          agenteSelected: result.data.data[0].value,
          id_agente: result.data.data[0].value
        });
        this.agentesRef.current.value = parseInt(result.data.data[0].value); 

        axios.get('/apiv2/CatalogosController.php/TiposPolizas')
        .then(result => {
          this.setState({
            loader: false,
            ddTiposPolizas: result.data.data,
            polizaSelected: result.data.data[0].value,
            id_tipo_poliza: result.data.data[0].value
          });
          this.tiposPolizasRef.current.value = parseInt(result.data.data[0].value); 

          if(this.props.match.params.id !== undefined){
            this.setState({loader: true});
            axios.get('/apiv2/RenovacionesPolizasAutomovilController.php/DetallesPolizaAutomovil',{
              params:{
                id:this.props.match.params.id,
              }
            })
            .then(result => {
              this.setState({
                loader: false,
                poliza: result.data.data[0].poliza,
                detalles: result.data.data[0].descripcion,
                cliente: result.data.data[0].cliente,
                telefono: result.data.data[0].telefono,
                correo: result.data.data[0].correo,
                fecha_emision:result.data.data[0].fecha_emision,
                id_tipo_poliza: result.data.data[0].id_tipo_poliza,
                id_agente: result.data.data[0].id_agente,
                calendario:result.data.data.calendario
              });

              this.tiposPolizasRef.current.value = parseInt(result.data.data[0].id_tipo_poliza);
              this.agentesRef.current.value = parseInt(result.data.data[0].id_agente);
            })
            .catch(console.log);      
          }

        }).catch(console.log);

      }).catch(console.log);
    }).catch(console.log);

    axios.get('/apiv2/RenovacionesPolizasAutomovilController.php/HistorialLlamadas',{
      params:{
        id:this.props.match.params.id,
      }
    }).then(result => {
      this.setState({
        historialLlamadas:result.data.data
      });
    }).catch(console.log);
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleChangeDate(event) {
    this.setState({fecha_lista: event});
  }

  saveModal = (identificador) => {
    if(identificador == 1 || identificador == 2){
      confirmAlert({
        message: 'Desea cambiar el estado de la poliza?',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.cambiarEstatus(identificador)
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }else if(identificador == 3){
      confirmAlert({
        message: 'Desea agregar la llamada?.',
        buttons: [
          {
            label: 'Continuar',
            onClick: () => this.guardarLlamada()
          },
          {
            label: 'Cancelar'
          }
        ],
        overlayClassName: 'backGroundDialog'
      });
    }
  }

  guardarLlamada(){
    this.setState({loader: true});
    axios.post('/apiv2/RenovacionesPolizasAutomovilController.php/AgregarLlamada',
    {
      id: this.props.match.params.id, 
      comentario: this.state.comentarioLlamada, 
      tipo: this.state.id_tipo_llamada
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        window.location.reload();
      },1200);
    })
    .catch(console.log);
  }

  cambiarEstatus(identificador){
    this.setState({loader: true});
    axios.post('/apiv2/RenovacionesPolizasAutomovilController.php/CambiarEstatus',
    {
      id: this.props.match.params.id,
      valor: identificador,
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      if(result.data.success){
        setTimeout(() => {
          window.location.reload();
        },1200);
      }
    })
    .catch(console.log);
  }

  cambiarFechaLista(){
    this.setState({loader: true});
    axios.post('/apiv2/RenovacionesPolizasAutomovilController.php/CambiarFechaLista',
    {
      id: this.props.match.params.id,
      fecha: this.state.fecha_lista
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        window.location.reload();
      },1200);
    })
    .catch(console.log);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>INFORMACION DEL CLIENTE</b>
                      </h3>
                      <div className="form-row justify-content-end">
                        <Link to="/listarenovacionespolizasautomovil">
                          <button type="button" 
                            className="btn-sm btn-danger btn-block">
                            <i className="fa fa-arrow-circle-left"></i> Volver
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="control-label">Poliza</label>
                        <input type="text" className="form-control" name="poliza" 
                        maxLength="60" value={this.state.poliza} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChange} disabled></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" name="telefono" 
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Correo</label>
                        <input type="text" className="form-control" name="correo" 
                        maxLength="60" value={this.state.correo} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Fecha De Emision</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_emision} disabled={true}/>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef} disabled>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label">Agente</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_agente} name="id_agente" ref={this.agentesRef} disabled>
                          {this.state.ddAgentes.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea type="text" className="form-control" name="detalles"
                        value={this.state.detalles} onChange={this.handleInputChange} disabled></textarea>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-5">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>DATOS DE LISTA</b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(1)} >
                          <i className="fa fa-plus-circle"></i> Renovo
                        </button>
                      </div>
                      <div className="form-group col-md-6">
                        <button type="button" 
                          className="btn btn-block bg-gradient-danger mt-4" 
                          onClick={() => this.saveModal(2)} >
                          <i className="fa fa-times-circle"></i> Eliminar
                        </button>
                      </div>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="control-label">Resultado</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_tipo_llamada} name="id_tipo_llamada" ref={this.tiposLlamadasRef}>
                          {this.state.ddResultadoLlamada.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.saveModal(3)} >
                          <i className="fa fa-phone"></i> Agregar
                        </button>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input type="text" className="form-control" name="comentarioLlamada" 
                          maxLength="60" value={this.state.comentarioLlamada} onChange={this.handleInputChange}></input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <div class="card-header">
                    <h3 class="card-title">
                      <b>Ultimas 5 Llamadas</b>
                    </h3>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Resultado</th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.historialLlamadas.map((result) => {
                        return(
                          <tr>
                            <td>{result.fecha_registro}</td>
                            <td>{result.nombre}</td>
                            <td>{result.descripcion}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default LlamadaRenovacionesAutomovil;