import React from 'react';
import "./multicotizador.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';

class Multicotizador extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      filtro: '',
      loader: false,
      axa:26,
      banorte:19,
      chubb:15,
      allianz:22,
      hdi:33,
      cliente:'',
      unidad:'',
      dataPrecios:{
        qualitasprecio:'',
        axaprecio:'',
        banorteprecio:'',
        chubbprecio:'',
        allianzprecio:'',
        hdiprecio:'',
      },
      ddTiposPolizas:[
        {id: 1, text: 'AMPLIA'},
        {id: 2, text: 'BASICA'},
        {id: 3, text: 'LIMITADA'}
      ],
      id_tipo_poliza:'AMPLIA',
      dataPoliza:[
        [
          {cobertura:'Daños Materiales',suma:'$ 250,000.00',deducible:''},
          {cobertura:'Robo Total',suma:'$ 250,000.00',deducible:''},
          {cobertura:'Responsabilidad Civil',suma:'$ 3,000,000.00',deducible:''},
          {cobertura:'Gastos Medicos Ocupantes',suma:'$ 200,000.00',deducible:''},
          {cobertura:'Gastos Legales',suma:'AMPARADO',deducible:''},
          {cobertura:'Asistencia Vial',suma:'AMPARADO',deducible:''},
          {cobertura:'Muerte del Conductor X AA',suma:'$ 100,000.00',deducible:''},
          {cobertura:'',suma:'',deducible:''},
          {cobertura:'',suma:'',deducible:''},
        ],
        [
          {cobertura:'Daños Materiales',suma:'Valor Comercial',deducible:''},
          {cobertura:'Robo Total',suma:'Valor Comercial',deducible:''},
          {cobertura:'Responsabilidad Civil',suma:'$ 3,000,000.00',deducible:''},
          {cobertura:'Gastos Medicos Ocupantes',suma:'$ 200,000.00',deducible:''},
          {cobertura:'Gastos Legales',suma:'AMPARADO',deducible:''},
          {cobertura:'Asistencia Vial',suma:'AMPARADO',deducible:''},
          {cobertura:'Muerte del Conductor X AA',suma:'$ 100,000.00',deducible:''},
          {cobertura:'',suma:'',deducible:''},
          {cobertura:'',suma:'',deducible:''},
        ],
        [
          {cobertura:'Daños Materiales',suma:'Valor Comercial',deducible:''},
          {cobertura:'Robo Total',suma:'Valor Comercial',deducible:''},
          {cobertura:'Responsabilidad Civil',suma:'$ 3,000,000.00',deducible:''},
          {cobertura:'Gastos Medicos Ocupantes',suma:'$ 200,000.00',deducible:''},
          {cobertura:'Gastos Legales',suma:'AMPARADO',deducible:''},
          {cobertura:'Asistencia Vial',suma:'AMPARADO',deducible:''},
          {cobertura:'Muerte del Conductor X AA',suma:'$ 100,000.00',deducible:''},
          {cobertura:'',suma:'',deducible:''},
          {cobertura:'',suma:'',deducible:''},
        ],
        [
          {cobertura:'Daños Materiales',suma:'Valor Comercial',deducible:''},
          {cobertura:'Robo Total',suma:'Valor Comercial',deducible:''},
          {cobertura:'Responsabilidad Civil',suma:'$ 3,000,000.00',deducible:''},
          {cobertura:'Gastos Medicos Ocupantes',suma:'$ 200,000.00',deducible:''},
          {cobertura:'Gastos Legales',suma:'AMPARADO',deducible:''},
          {cobertura:'Asistencia Vial',suma:'AMPARADO',deducible:''},
          {cobertura:'Muerte del Conductor X AA',suma:'$ 100,000.00',deducible:''},
          {cobertura:'',suma:'',deducible:''},
          {cobertura:'',suma:'',deducible:''},
        ],
        [
          {cobertura:'Daños Materiales',suma:'Valor Comercial',deducible:''},
          {cobertura:'Robo Total',suma:'Valor Comercial',deducible:''},
          {cobertura:'Responsabilidad Civil',suma:'$ 3,000,000.00',deducible:''},
          {cobertura:'Gastos Medicos Ocupantes',suma:'$ 200,000.00',deducible:''},
          {cobertura:'Gastos Legales',suma:'AMPARADO',deducible:''},
          {cobertura:'Asistencia Vial',suma:'AMPARADO',deducible:''},
          {cobertura:'Muerte del Conductor X AA',suma:'$ 100,000.00',deducible:''},
          {cobertura:'',suma:'',deducible:''},
          {cobertura:'',suma:'',deducible:''},
        ],
        [
          {cobertura:'Daños Materiales',suma:'Valor Comercial',deducible:''},
          {cobertura:'Robo Total',suma:'Valor Comercial',deducible:''},
          {cobertura:'Responsabilidad Civil',suma:'$ 3,000,000.00',deducible:''},
          {cobertura:'Gastos Medicos Ocupantes',suma:'$ 200,000.00',deducible:''},
          {cobertura:'Gastos Legales',suma:'AMPARADO',deducible:''},
          {cobertura:'Asistencia Vial',suma:'AMPARADO',deducible:''},
          {cobertura:'Muerte del Conductor X AA',suma:'$ 100,000.00',deducible:''},
          {cobertura:'',suma:'',deducible:''},
          {cobertura:'',suma:'',deducible:''},
        ]
      ]
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeText = this.handleInputChangeText.bind(this);
    this.handleInputChangeCost = this.handleInputChangeCost.bind(this);
    this.tiposPolizasRef = React.createRef();
    this.checkPerm();
    this.reloadPage();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleInputChange(event) {
    var valores = event.target.name.split('-');
    this.state.dataPoliza[valores[0]][valores[1]][valores[2]] = event.target.value;
    this.setState({filtro:''});
  }

  calcular(event) {
    this.state.dataPrecios['axaprecio'] = parseInt(this.state.dataPrecios['qualitasprecio'])+parseInt(((this.state.axa/100)*this.state.dataPrecios['qualitasprecio']));
    this.state.dataPrecios['banorteprecio'] = parseInt(this.state.dataPrecios['qualitasprecio'])+parseInt(((this.state.banorte/100)*this.state.dataPrecios['qualitasprecio']));
    this.state.dataPrecios['chubbprecio'] = parseInt(this.state.dataPrecios['qualitasprecio'])+parseInt(((this.state.chubb/100)*this.state.dataPrecios['qualitasprecio']));
    this.state.dataPrecios['allianzprecio'] = parseInt(this.state.dataPrecios['qualitasprecio'])+parseInt(((this.state.allianz/100)*this.state.dataPrecios['qualitasprecio']));
    this.state.dataPrecios['hdiprecio'] = parseInt(this.state.dataPrecios['qualitasprecio'])+parseInt(((this.state.hdi/100)*this.state.dataPrecios['qualitasprecio']));
  
    this.state.dataPoliza[0].map((value, i) => {   
      if(value.cobertura != 'Daños Materiales' && value.cobertura != 'Robo Total'){
        this.state.dataPoliza[1][i].cobertura = value.cobertura;
        this.state.dataPoliza[1][i].suma = value.suma;
        this.state.dataPoliza[1][i].deducible = value.deducible;

        this.state.dataPoliza[2][i].cobertura = value.cobertura;
        this.state.dataPoliza[2][i].suma = value.suma;
        this.state.dataPoliza[2][i].deducible = value.deducible;

        this.state.dataPoliza[4][i].cobertura = value.cobertura;
        this.state.dataPoliza[4][i].suma = value.suma;
        this.state.dataPoliza[4][i].deducible = value.deducible;

        this.state.dataPoliza[5][i].cobertura = value.cobertura;
        this.state.dataPoliza[5][i].suma = value.suma;
        this.state.dataPoliza[5][i].deducible = value.deducible;

        //this.state.dataPoliza[3][i].cobertura = value.cobertura;
        //this.state.dataPoliza[3][i].suma = value.suma;
        //this.state.dataPoliza[3][i].deducible = value.deducible;
        
        this.setState({filtro:''});              
      }
    });
  }

  reloadPage(){
    axios.get('/apiv2/MulticotizadorController.php/Multicotizador')
    .then(result => {
      this.setState({
        dataTable: result.data.data,
        loader:false,
      });
    }).catch(console.log);
  }

  guardarCotizacion = () => {
    if(this.state.cliente == ''){
      infoMessage(2, 'Error de captura','Capture el cliente para poder guardar la cotización.');
      return false;
    }
    if(this.state.unidad == ''){
      infoMessage(2, 'Error de captura','Capture la unidad para poder guardar la cotización.');
      return false;
    }
    this.setState({loader: true});
    axios.post('/apiv2/MulticotizadorController.php/Multicotizador',
    {
      id_usuario:localStorage.getItem('NAid'),
      cliente: this.state.cliente, 
      unidad: this.state.unidad,
      json:JSON.stringify({
        cliente: this.state.cliente,
        unidad: this.state.unidad,
        dataPrecios: this.state.dataPrecios,
        id_tipo_poliza: this.state.id_tipo_poliza,
        dataPoliza: this.state.dataPoliza
      })
    })
    .then(result => {
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        this.reloadPage();
      },1200);
    })
    .catch(console.log);
  }

  cargarCotizacion(json){
    var arreglo = JSON.parse(json);
    this.setState({
      cliente: arreglo.cliente,
      unidad: arreglo.unidad,
      dataPrecios: arreglo.dataPrecios,
      id_tipo_poliza: arreglo.id_tipo_poliza,
      dataPoliza: arreglo.dataPoliza
    });
  }

  generarPDF($type){
    if($type == 'Especial'){
      window.open('/apiv2/ReportesController.php/MulticotizadorFraccionado?'
        +'tipopoliza='+this.state.id_tipo_poliza
        +'&data='+JSON.stringify(this.state.dataPoliza)
        +'&cliente='+this.state.cliente
        +'&unidad='+this.state.unidad
        +'&dataprecios='+JSON.stringify(this.state.dataPrecios)
        +'&axaprecio='+this.state.axaprecio
        +'&banorteprecio='+this.state.banorteprecio
        +'&chubbprecio='+this.state.chubbprecio
        +'&allianzprecio='+this.state.allianzprecio
        +'&hdiprecio='+this.state.hdiprecio
        +'&tipopoliza='+this.state.id_tipo_poliza
        ).focus();
    }else{
      window.open('/apiv2/ReportesController.php/Multicotizador?'
        +'tipopoliza='+this.state.id_tipo_poliza
        +'&data='+JSON.stringify(this.state.dataPoliza)
        +'&cliente='+this.state.cliente
        +'&unidad='+this.state.unidad
        +'&dataprecios='+JSON.stringify(this.state.dataPrecios)
        +'&axaprecio='+this.state.axaprecio
        +'&banorteprecio='+this.state.banorteprecio
        +'&chubbprecio='+this.state.chubbprecio
        +'&allianzprecio='+this.state.allianzprecio
        +'&hdiprecio='+this.state.hdiprecio
        +'&tipopoliza='+this.state.id_tipo_poliza
        ).focus();
      //, '_blank').focus();
    }
  }

  handleInputChangeText(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  handleInputChangeCost(event) {
    this.state.dataPrecios[event.target.name] = event.target.value;
    this.setState({filtro:''});
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar" style={{height:"200px"}}>
                    <table className="table table-bordered table-striped mb-0">
                      <thead>
                        <tr className="Table-Details">
                          <th>Usuario</th>
                          <th>Cliente</th>
                          <th>Unidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTable.map((result) => {
                          return(
                            <tr class="navbar-btn sidebar-toggle" data-toggle="offcanvas" role="button" 
                              onDoubleClick={() => this.cargarCotizacion(result.json)}>
                              <td>{result.nombre}</td>
                              <td>{result.cliente}</td>
                              <td>{result.unidad}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Cliente</label>
                        <input type="text" className="form-control" placeholder="Cliente" name="cliente" 
                        maxLength="60" value={this.state.cliente} onChange={this.handleInputChangeText} ></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Unidad</label>
                        <input type="text" className="form-control" placeholder="Unidad" name="unidad"
                        maxLength="60" value={this.state.unidad} onChange={this.handleInputChangeText} ></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="control-label">Tipo De Poliza</label>
                        <select class="custom-select" onChange={this.handleInputChangeText} 
                          value={this.state.id_tipo_poliza} name="id_tipo_poliza" ref={this.tiposPolizasRef}>
                          {this.state.ddTiposPolizas.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group required col-md-2">
                        <label className="control-label">Qualitas</label>
                        <input type="number" className="form-control" placeholder="Costo Anual" name="qualitasprecio" 
                        maxLength="60" value={this.state.dataPrecios.qualitasprecio} onChange={this.handleInputChangeCost} ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">Axa</label>
                        <input type="number" className="form-control" placeholder="Costo Anual" name="axaprecio"
                        maxLength="60" value={this.state.dataPrecios.axaprecio} onChange={this.handleInputChangeCost} ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">Banore</label>
                        <input type="number" className="form-control" placeholder="Costo Anual" name="banorteprecio"
                        maxLength="60" value={this.state.dataPrecios.banorteprecio} onChange={this.handleInputChangeCost} ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">Chubb</label>
                        <input type="number" className="form-control" placeholder="Costo Anual" name="chubbprecio"
                        maxLength="60" value={this.state.dataPrecios.chubbprecio} onChange={this.handleInputChangeCost} ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">Allianz</label>
                        <input type="number" className="form-control" placeholder="Costo Anual" name="allianzprecio"
                        maxLength="60" value={this.state.dataPrecios.allianzprecio} onChange={this.handleInputChangeCost} ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">HDI</label>
                        <input type="number" className="form-control" placeholder="Costo Anual" name="hdiprecio"
                        maxLength="60" value={this.state.dataPrecios.hdiprecio} onChange={this.handleInputChangeCost} ></input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div class="card-header d-flex p-0">
                    <h2 class="card-title p-3">Multicotizador</h2>
                    <div className="mr-2 mt-3">
                      <button type="button" 
                        className="btn btn-info btn-block" 
                        style={{width: "150px"}} 
                        onClick={() => this.calcular()} >
                        <i className="fa fa-plus-circle"></i> Llenar Polizas
                      </button>
                    </div>
                    <div className="input-group-append mr-2 mt-3 mb-2">
                      <button type="submit" className="btn btn-danger"
                      onClick={() => this.generarPDF('Normal')} >
                        <i className="fas fa-file-pdf"></i>
                      </button>
                    </div>
                    <div className="input-group-append mr-2 mt-3 mb-2">
                      <button type="submit" className="btn btn-success"
                      onClick={() => this.generarPDF('Especial')} >
                        <i className="fas fa-file-pdf"></i>
                      </button>
                    </div>
                    <div className="input-group-append mr-2 mt-3 mb-2">
                      <button type="submit" className="btn btn-primary"
                      onClick={() => this.guardarCotizacion()} >
                        <i className="far fa-save"></i>
                      </button>
                    </div>
                    <ul class="nav nav-pills ml-auto p-2">
                      <li class="nav-item"><a class="nav-link active" href="#tab_1" data-toggle="tab">Qualitas</a></li>
                      <li class="nav-item"><a class="nav-link" href="#tab_2" data-toggle="tab">Axa</a></li>
                      <li class="nav-item"><a class="nav-link" href="#tab_3" data-toggle="tab">banorte</a></li>
                      <li class="nav-item"><a class="nav-link" href="#tab_4" data-toggle="tab">CHUBB</a></li>
                      <li class="nav-item"><a class="nav-link" href="#tab_5" data-toggle="tab">Allianz</a></li>
                      <li class="nav-item"><a class="nav-link" href="#tab_6" data-toggle="tab">HDI</a></li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content">
                      <div class="tab-pane active" id="tab_1">
                        <div className="col-12 mt-3">
                          <div className="form-row">
                            <div className="form-group required col-md-6">
                              <label className="control-label">Cobertura</label>
                              <input type="text" className="form-control" name="0-0-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][0].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Suma Asegurada</label>
                              <input type="text" className="form-control" name="0-0-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][0].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Deducible</label>
                              <input type="text" className="form-control" name="0-0-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][0].deducido} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-1-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][1].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-1-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][1].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-1-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][1].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-2-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][2].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-2-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][2].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-2-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][2].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-3-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][3].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-3-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][3].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-3-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][3].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-4-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][4].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-4-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][4].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-4-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][4].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-5-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][5].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-5-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][5].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-5-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][5].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-6-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][6].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-6-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][6].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-6-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][6].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-7-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][7].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-7-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][7].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-7-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][7].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="0-8-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[0][8].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-8-suma" 
                              maxLength="60" value={this.state.dataPoliza[0][8].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="0-8-deducible" 
                              maxLength="60" value={this.state.dataPoliza[0][8].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab_2">
                        <div className="col-12 mt-3">
                          <div className="form-row">
                            <div className="form-group required col-md-6">
                              <label className="control-label">Cobertura</label>
                              <input type="text" className="form-control" name="1-0-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][0].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Suma Asegurada</label>
                              <input type="text" className="form-control" name="1-0-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][0].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Deducible</label>
                              <input type="text" className="form-control" name="1-0-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][0].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-1-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][1].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-1-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][1].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-1-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][1].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-2-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][2].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-2-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][2].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-2-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][2].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-3-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][3].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-3-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][3].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-3-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][3].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-4-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][4].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-4-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][4].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-4-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][4].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-5-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][5].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-5-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][5].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-5-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][5].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-6-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][6].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-6-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][6].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-6-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][6].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-7-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][7].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-7-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][7].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-7-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][7].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="1-8-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[1][8].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-8-suma" 
                              maxLength="60" value={this.state.dataPoliza[1][8].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="1-8-deducible" 
                              maxLength="60" value={this.state.dataPoliza[1][8].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab_3">
                        <div className="col-12 mt-3">
                          <div className="form-row">
                            <div className="form-group required col-md-6">
                              <label className="control-label">Cobertura</label>
                              <input type="text" className="form-control" name="2-0-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][0].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Suma Asegurada</label>
                              <input type="text" className="form-control" name="2-0-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][0].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Deducible</label>
                              <input type="text" className="form-control" name="2-0-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][0].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-1-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][1].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-1-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][1].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-1-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][1].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-2-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][2].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-2-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][2].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-2-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][2].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-3-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][3].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-3-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][3].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-3-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][3].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-4-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][4].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-4-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][4].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-4-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][4].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-5-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][5].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-5-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][5].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-5-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][5].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-6-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][6].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-6-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][6].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-6-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][6].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-7-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][7].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-7-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][7].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-7-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][7].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="2-8-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[2][8].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-8-suma" 
                              maxLength="60" value={this.state.dataPoliza[2][8].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="2-8-deducible" 
                              maxLength="60" value={this.state.dataPoliza[2][8].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab_4">
                        <div className="col-12 mt-3">
                          <div className="form-row">
                            <div className="form-group required col-md-6">
                              <label className="control-label">Cobertura</label>
                              <input type="text" className="form-control" name="3-0-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][0].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Suma Asegurada</label>
                              <input type="text" className="form-control" name="3-0-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][0].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Deducible</label>
                              <input type="text" className="form-control" name="3-0-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][0].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-1-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][1].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-1-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][1].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-1-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][1].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-2-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][2].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-2-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][2].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-2-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][2].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-3-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][3].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-3-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][3].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-3-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][3].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-4-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][4].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-4-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][4].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-4-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][4].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-5-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][5].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-5-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][5].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-5-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][5].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-6-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][6].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-6-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][6].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-6-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][6].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-7-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][7].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-7-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][7].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-7-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][7].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="3-8-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[3][8].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-8-suma" 
                              maxLength="60" value={this.state.dataPoliza[3][8].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="3-8-deducible" 
                              maxLength="60" value={this.state.dataPoliza[3][8].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab_5">
                        <div className="col-12 mt-3">
                          <div className="form-row">
                            <div className="form-group required col-md-6">
                              <label className="control-label">Cobertura</label>
                              <input type="text" className="form-control" name="4-0-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][0].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Suma Asegurada</label>
                              <input type="text" className="form-control" name="4-0-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][0].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Deducible</label>
                              <input type="text" className="form-control" name="4-0-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][0].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-1-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][1].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-1-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][1].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-1-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][1].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-2-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][2].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-2-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][2].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-2-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][2].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-3-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][3].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-3-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][3].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-3-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][3].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-4-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][4].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-4-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][4].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-4-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][4].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-5-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][5].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-5-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][5].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-5-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][5].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-6-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][6].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-6-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][6].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-6-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][6].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-7-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][7].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-7-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][7].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-7-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][7].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="4-8-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[4][8].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-8-suma" 
                              maxLength="60" value={this.state.dataPoliza[4][8].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="4-8-deducible" 
                              maxLength="60" value={this.state.dataPoliza[4][8].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab_6">
                        <div className="col-12 mt-3">
                          <div className="form-row">
                            <div className="form-group required col-md-6">
                              <label className="control-label">Cobertura</label>
                              <input type="text" className="form-control" name="5-0-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][0].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Suma Asegurada</label>
                              <input type="text" className="form-control" name="5-0-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][0].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <label className="control-label">Deducible</label>
                              <input type="text" className="form-control" name="5-0-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][0].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-1-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][1].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-1-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][1].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-1-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][1].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-2-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][2].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-2-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][2].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-2-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][2].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-3-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][3].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-3-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][3].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-3-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][3].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-4-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][4].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-4-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][4].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-4-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][4].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-5-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][5].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-5-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][5].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-5-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][5].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-6-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][6].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-6-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][6].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-6-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][6].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-7-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][7].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-7-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][7].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-7-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][7].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-6">
                              <input type="text" className="form-control" name="5-8-cobertura" 
                              maxLength="60" value={this.state.dataPoliza[5][8].cobertura} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-8-suma" 
                              maxLength="60" value={this.state.dataPoliza[5][8].suma} onChange={this.handleInputChange} ></input>
                            </div>
                            <div className="form-group required col-md-3">
                              <input type="text" className="form-control" name="5-8-deducible" 
                              maxLength="60" value={this.state.dataPoliza[5][8].deducible} onChange={this.handleInputChange} ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default Multicotizador;