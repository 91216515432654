import React, { createElement, useState } from "react";
import "./ListaCancelacionesAutos.css";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { Link } from "react-router-dom";
import infoMessage from "../../components/infoMessage/InfoMessage";
import DatePicker from "react-date-picker";
import { confirmAlert } from "react-confirm-alert";

class ListaCancelacionesAutos extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          dataTable: [],
          filtro: '',
          loader: true,
          currentPage: 0,
          totalPages: 1,
          limitPage: 10
        };
    
        this.loadTableData();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPerm();
      }

      
        checkPerm = async() => {
            var url = window.location.href;
            var lastSegment = url.split("/");
            var ruta = lastSegment[3];
            var perms = JSON.parse(localStorage.getItem('NApermisos'));
            var rett = true;
            await Promise.all(perms.map(async (perm) => {
            if(perm.uri == ruta){
                rett = false;
                return false;
            }
            }));
            if(rett){
            this.props.history.push("/error");
            }
        }

        loadTableData = () => {
            this.setState({loader: true});
            axios.get('/apiv2/CancelacionesAutosController.php/CancelacionesAutos',
            {
              params:{
                q:this.state.filtro
              }
            })
            .then(result => {
              setTimeout(() => {
                this.setState({loader: false});
                this.setState({
                  dataTable: result.data.data
                });
              },1000);
            })
            .catch(console.log);
          }

          handleChange(event) {
            this.setState({filtro: event.target.value});
          }
        
          handleSubmit(event) {
            this.setState({loader: true});
            this.setState({
              currentPage : 0
            });
            setTimeout(() => {
              this.loadTableData();
            }, 800);
            event.preventDefault();
          }

          estatusModal = (identificador, detalle, estatus) => {
            if(estatus == 1){
              var msg = 'activar';
            }else{
              var msg = 'eliminar';
            }
            confirmAlert({
              message: 'Desea '+msg+' el registro['+detalle+'].',
              buttons: [
                {
                  label: 'Continuar',
                  onClick: () => this.cambiarEstatus(identificador, estatus)
                },
                {
                  label: 'Cancelar'
                }
              ],
              overlayClassName: 'backGroundDialog'
            });
          }

          cambiarEstatus = (identificador, estatus) => {
            this.setState({loader: true});
            axios.delete('/apiv2/CancelacionesAutosController.php/CancelacionesAutos?id_cancelacion_auto='+identificador+'&estatus='+estatus)
            .then(result => {
              this.setState({loader: false});
              infoMessage(1, 'Mensaje del sistema', result.data.message);
              this.loadTableData();
            })
            .catch(console.log);
          }

          render(){
            return (
              <LoadingOverlay
                active={this.state.loader}
                spinner={<RingLoader />}
                className="container-fluid min-vh-100"
              >
                  <div className="wrapper" style={{paddingTop:"10px"}}>
                  <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                        <div className="card">
                          <div className="card-header d-flex justify-content-center">
                            <h3>Razones Cancelaciones Automovil</h3>
                          </div>
                          <div className="card-header d-flex justify-content-start">    
                            <div className="mr-2">
                              <Link to="/nuevacancelacionesautos">
                                <button type="button" 
                                  className="btn btn-success btn-block btn-sm" 
                                  style={{width: "150px"}} >
                                  <i className="fa fa-plus-circle"></i> Agregar
                                </button>
                              </Link>
                            </div>
                          </div>
                          <div className="table-wrapper-scroll-y my-custom-scrollbar">
                            <table className="table table-bordered table-striped mb-0">
                              <thead>
                                <tr className="Table-Details">
                                  <th>Nombre</th>
                                  <th>Descripciones</th>
                                  <th>Acciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataTable.map((result) => {
                                  return(
                                    <tr class="navbar-btn sidebar-toggle" data-toggle="offcanvas" role="button" data-tooltip="tooltip" 
                                      title={result.descripcion} style={{backgroundColor: result.colorbg}}>
                                      <td>{result.nombre}</td>
                                      <td>{result.descripcion}</td>
                                      <td> 
                                        <div class="d-flex justify-content-center">
                                          <div className="input-group-append mr-2">
                                            <Link to={"/editarcancelacionesautos/"+result.id_cancelacion_auto}>
                                              <button type="submit" className="btn btn-primary btn-sm">
                                                <i className="fas fa-pencil-alt"></i>
                                              </button>
                                            </Link>
                                          </div>
                                          { result.estatus == 1 && 
                                            <div className="input-group-append">
                                              <button type="submit" className="btn btn-danger btn-sm" 
                                                onClick={() => this.estatusModal(result.id_cancelacion_auto, result.nombre, 0)}>
                                                <i className="fas fa-trash-alt"></i>
                                              </button>
                                            </div>
                                          }
                                          { result.estatus == 0 && 
                                            <div className="input-group-append">
                                              <button type="submit" className="btn btn-success btn-sm" 
                                                onClick={() => this.estatusModal(result.id_cancelacion_auto, result.nombre, 1)}>
                                                <i className="fas fa-thumbs-up"></i>
                                              </button>
                                            </div>
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </LoadingOverlay>
            );
          }


    }

export default ListaCancelacionesAutos;