import React, { createElement, useState } from "react";
import "./polizasGMM.css";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { Link } from "react-router-dom";
import infoMessage from "../../components/infoMessage/InfoMessage";
import DatePicker from "react-date-picker";

class PolizasGMM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      poliza: "",
      detalles: "",
      id_cliente: "",
      id_tipo_poliza: "",
      id_agente: "",
      id_compania_gmm: "",
      prima_neta: 0,
      nueva: 1,
      fecha_emision: new Date(),
      ddTiposPolizas: [],
      ddAgentes: [],
      ddClientes: [],
      ddCompania: [],
      calendario: [],
      showCalendar: false,
      id_poliza_gmm:'',
      ddExistente: [
        {
          value: 1,
          text: "NUEVA",
        },
        {
          value: 0,
          text: "RENOVACION",
        },
      ],
      //asegurados:[]
      asegurado1: "",
      asegurado2: "",
      asegurado3: "",
      asegurado4: "",
      asegurado5: "",
      asegurado6: "",
      asegurado7: "",
      asegurado8: "",
      fechaDeNacimiento1: new Date(),
      fechaDeNacimiento2: new Date(),
      fechaDeNacimiento3: new Date(),
      fechaDeNacimiento4: new Date(),
      fechaDeNacimiento5: new Date(),
      fechaDeNacimiento6: new Date(),
      fechaDeNacimiento7: new Date(),
      fechaDeNacimiento8: new Date(),
      primaNeta1: "",
      primaNeta2: "",
      primaNeta3: "",
      primaNeta4: "",
      primaNeta5: "",
      primaNeta6: "",
      primaNeta7: "",
      primaNeta8: "",
    };
    
    this.tiposPolizasRef = React.createRef();
    this.agentesRef = React.createRef();
    this.clientesRef = React.createRef();
    this.companiaRef = React.createRef();
    this.existenteRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.loadAll();
    this.checkPerm();
    
  }


  loadAll = () => {
    
  
    this.setState({ loader: true });
    axios.get("/apiv2/CatalogosController.php/Agentes")
      .then((result) => {
        this.setState({
          ddAgentes: result.data.data,
          agenteSelected: result.data.data[0].value,
          id_agente: result.data.data[0].value
        });
        this.agentesRef.current.value = parseInt(result.data.data[0].value);

        axios.get("/apiv2/CatalogosController.php/Clientes")
          .then((result) => {
            this.setState({
              ddClientes: result.data.data,
              clienteSelected: result.data.data[0].value,
              id_cliente: result.data.data[0].value,
            });
            this.clientesRef.current.value = parseInt(
              result.data.data[0].value
            );

            axios.get("/apiv2/CatalogosController.php/CompaniasGMM")
              .then((result) => {
                this.setState({
                  ddCompania: result.data.data,
                  companiaSelected: result.data.data[0].value,
                  id_compania_gmm: result.data.data[0].value,
                });
                this.companiaRef.current.value = parseInt(
                  result.data.data[0].value
                );

                axios.get("/apiv2/CatalogosController.php/TiposPolizas")
                  .then((result) => {
                    this.setState({
                      ddTiposPolizas: result.data.data,
                      polizaSelected: result.data.data[0].value,
                      id_tipo_poliza: result.data.data[0].value,
                    });
                    this.tiposPolizasRef.current.value = parseInt(
                      result.data.data[0].value
                    );
                    this.setState({ loader: false });
                    if (this.props.match.params.id !== undefined) {
                      axios
                        .get(
                          "/apiv2/PolizasGMMController.php/DetallesPolizaGMM",
                          {
                            params: {
                              id: this.props.match.params.id,
                            },
                          }
                        )
                        .then((result) => {
                          
                          var datee = new Date(
                            result.data.data[0].fecha_emision
                          );

                          for (let i = 0; i < result.data.data.asegurados.length; i++) {
                            let aseguradoProp = 'asegurado' + (i + 1);
                            let fechaNacimientoProp = 'fechaDeNacimiento' + (i + 1);
                            let primaNetaProp = 'primaNeta' + (i + 1);
                            if (result.data.data.asegurados[i].nombre !== '' && result.data.data.asegurados[i].prima_neta !== '') {
                              var dateee = new Date(result.data.data.asegurados[i].fecha_nacimiento);
                              var stateUpdate = {};
                              stateUpdate[aseguradoProp] = result.data.data.asegurados[i].nombre;
                              stateUpdate[fechaNacimientoProp] = new Date(dateee.setDate(dateee.getDate() + 1));
                              stateUpdate[primaNetaProp] = result.data.data.asegurados[i].prima_neta;
                          
                              this.setState(stateUpdate);
                            }
                          }
                            
                          this.setState({
                            poliza: result.data.data[0].poliza,
                            detalles: result.data.data[0].descripcion,
                            cliente: result.data.data[0].cliente,
                            telefono: result.data.data[0].telefono,
                            correo: result.data.data[0].correo,
                            prima_neta: result.data.data[0].prima_neta,
                            id_poliza_gmm: result.data.data[0].id_poliza_gmm,
                            fecha_emision: new Date(
                              datee.setDate(datee.getDate() + 1)
                            ),
                            id_compania_gmm:
                            result.data.data[0].id_compania_gmm,
                            id_tipo_poliza: result.data.data[0].id_tipo_poliza,
                            id_cliente: result.data.data[0].id_cliente,
                            id_agente: result.data.data[0].id_agente,
                            nueva: result.data.data[0].nueva,
                          });
                          axios.get("/apiv2/PagoPolizasGMMController.php/DetallesPolizaGMM",
                              {
                                params: {
                                  id: this.props.match.params.id,
                                },
                              }
                            )
                            .then((result) => {
                              this.setState({
                                calendario: result.data.data.calendario,
                                showCalendar: true,
                              });
                            })
                            .catch(console.log);

                          this.tiposPolizasRef.current.value = parseInt(
                            result.data.data[0].id_tipo_poliza
                          );
                          this.agentesRef.current.value = parseInt(
                            result.data.data[0].id_agente
                          );
                          this.clientesRef.current.value = parseInt(
                            result.data.data[0].id_cliente
                          );
                          this.companiaRef.current.value = parseInt(
                            result.data.data[0].id_compania_gmm
                          );
                          this.existenteRef.current.value = parseInt(
                            result.data.data[0].nueva
                          );
                        })
                        .catch(console.log);
                    }
                  })
                  .catch(console.log);
              })
              .catch(console.log);
          })
          .catch(console.log);
      })
      .catch(console.log);
  };

  checkPerm = async () => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem("NApermisos"));
    var rett = true;
    await Promise.all(
      perms.map(async (perm) => {
        if (perm.uri == ruta) {
          rett = false;
          return false;
        }
      })
    );
    if (rett) {
      this.props.history.push("/error");
    }
  };

    handleInputChange(event) {
      const target = event.target;
      var value = target.value;
      const name = target.name;
      if (target.type === "checkbox") {
        if (target.checked) {
          this.state.hobbies[value] = value;
        } else {
          this.state.hobbies.splice(value, 1);
        }
      } else {
        this.setState({
          [name]: value,
        });
      }
    }

  handleChangeDate(event, name) {
    if (Object.prototype.toString.call(event) === "[object Date]") {
      if (isNaN(event)) {
      } else {
        switch (name) {
          case "fecha_emision":
            this.setState({ fecha_emision: event });
            break;
          case "fechaDeNacimiento1":
            this.setState({ fechaDeNacimiento1: event });
            break;
          case "fechaDeNacimiento2":
            this.setState({ fechaDeNacimiento2: event });
            break;
            case 'fechaDeNacimiento3':
            this.setState({fechaDeNacimiento3 : event})
            break;
            case 'fechaDeNacimiento4':
            this.setState({fechaDeNacimiento4 : event})
            break;
            case 'fechaDeNacimiento5':
            this.setState({fechaDeNacimiento5 : event})
            break;
            case 'fechaDeNacimiento6':
            this.setState({fechaDeNacimiento6 : event})
            break;
            case 'fechaDeNacimiento7':
            this.setState({fechaDeNacimiento7 : event})
            break;
            case 'fechaDeNacimiento8':
            this.setState({fechaDeNacimiento8 : event})
            break;
        }
      }
    } else {
    }
  }
  
  guardarPoliza() {
    //VALIDAR QUE LA PRIMERA FILA TENGA NOMBRE Y PRIMA NETA
    //SI UNA FILA TIENE NOMBRE OBLIGATORIA DEBE TENER PRIMA NETA
    for (let i = 1; i < 9; i++) {
      if (i == 1) {
        if (this.state.asegurado1 == "" || this.state.primaNeta1 == "") {
          infoMessage(
            2,
            "Error de captura",
            "Capture los datos del primer asegurado para poder continuar."
          );
          return false;
        }
      } else {
        let aseguradoEspecial = "asegurado" + i;
        let primaNetaEspecial = "primaNeta" + i;
        if (this.state[aseguradoEspecial] != "") {
          if (this.state[primaNetaEspecial] == "") {
            infoMessage(
              2,
              "Error de captura",
              "A un asegurado le hace falta prima neta"
            );
            return false;
          }
        }
      }
    }
    //VALIDAR QUE LOS CAMPOS OBLIGATORIOS NO ESTEN VACIOS
    if (this.state.poliza == "") {
      infoMessage(
        2,
        "Error de captura",
        "Capture la poliza para poder continuar."
      );
      return false;
    }
    if (this.state.cliente == "") {
      infoMessage(
        2,
        "Error de captura",
        "Capture el cliente para poder continuar."
      );
      return false;
    }
    if (this.state.telefono == "") {
      infoMessage(
        2,
        "Error de captura",
        "Capture el telefono para poder continuar."
      );
      return false;
    }
    if (this.state.fecha_emision == "") {
      infoMessage(
        2,
        "Error de captura",
        "Capture la fecha de emision para poder continuar."
      );
      return false;
    }
    if (this.state.id_cliente == "") {
      infoMessage(
        2,
        "Error de captura",
        "Capture el cliente para poder continuar."
      );
      return false;
    }
    if (this.state.id_agente == "") {
      infoMessage(
        2,
        "Error de captura",
        "Capture el agente para poder continuar."
      );
      return false;
    }
    if (this.state.id_tipo_poliza == "") {
      infoMessage(
        2,
        "Error de captura",
        "Capture el tipo de poliza para poder continuar."
      );
      return false;
    }
     
    
    //aqui el array de objetos
    this.setState({ loader: true });
    console.log(this)
    if (this.props.match.params.id !== undefined) {
      axios
        .put("/apiv2/PolizasGMMController.php/PolizasGMM", {
          id: this.props.match.params.id,
          poliza: this.state.poliza,
          cliente: this.state.cliente,
          telefono: this.state.telefono,
          correo: this.state.correo,
          prima_neta: this.state.prima_neta,
          fecha_emision: new Date(this.state.fecha_emision.setHours(0, 0, 0, 0))
            .toISOString()
            .split('T')[0],
          id_agente: this.state.id_agente,
          id_cliente: this.state.id_cliente,
          id_tipo_poliza: this.state.id_tipo_poliza,
          id_compania_gmm: this.state.id_compania_gmm,
          detalles: this.state.detalles,
          nueva: this.state.nueva,
          idusuario: localStorage.getItem("NAid"),
          id_poliza_gmm: this.state.id_poliza_gmm,
          asegurados: [
            {
              asegurado: this.state.asegurado1,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento1.setHours(0, 0, 0, 0)
                )
                .toISOString()
                .split('T')[0],
              primaNeta: this.state.primaNeta1,
            },
            {
              asegurado: this.state.asegurado2,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento2.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta2,
            },
            {
              asegurado: this.state.asegurado3,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento3.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta3,
            },
            {
              asegurado: this.state.asegurado4,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento4.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta4,
            },
            {
              asegurado: this.state.asegurado5,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento5.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta5,
            },
            {
              asegurado: this.state.asegurado6,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento6.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta6,
            },
            {
              asegurado: this.state.asegurado7,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento7.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta7,
            },
            {
              asegurado: this.state.asegurado8,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento8.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta8,
            },
          ],
        }) 
        .then(result => {
          this.setState({ loader: false });
          let tipo = 1;
          if (result.data.success) {
            tipo = 1;
          } else {
            tipo = 2;
          }
          infoMessage(tipo, "Mensaje del sistema", result.data.message);
        })
        .catch(console.log);
    } else {
      axios
        .post("/apiv2/PolizasGMMController.php/PolizasGMM", {
          id_poliza_gmm: this.state.poliza,
          poliza: this.state.poliza,
          cliente: this.state.cliente,
          telefono: this.state.telefono,
          correo: this.state.correo,
          prima_neta: this.state.prima_neta,
          fecha_emision: new Date(this.state.fecha_emision.setHours(0, 0, 0, 0))
            .toISOString()
            .split("T")[0],
          id_agente: this.state.id_agente,
          id_cliente: this.state.id_cliente,
          id_tipo_poliza: this.state.id_tipo_poliza,
          id_compania_gmm: this.state.id_compania_gmm,
          detalles: this.state.detalles,
          nueva: this.state.nueva,
          idusuario: localStorage.getItem("NAid"),
          asegurados: [
            {
              asegurado: this.state.asegurado1,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento1.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta1,
            },
            {
              asegurado: this.state.asegurado2,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento2.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta2,
            },
            {
              asegurado: this.state.asegurado3,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento3.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta3,
            },
            {
              asegurado: this.state.asegurado4,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento4.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta4,
            },
            {
              asegurado: this.state.asegurado5,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento5.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta5,
            },
            {
              asegurado: this.state.asegurado6,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento6.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta6,
            },
            {
              asegurado: this.state.asegurado7,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento7.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta7,
            },
            {
              asegurado: this.state.asegurado8,
              fechaDeNacimiento: new Date(
                this.state.fechaDeNacimiento8.setHours(0, 0, 0, 0)
              )
                .toISOString()
                .split("T")[0],
              primaNeta: this.state.primaNeta8,
            },
          ],
        })
        .then((result) => {
          this.setState({ loader: false });
          let tipo = 1;
          if (result.data.success) {
            tipo = 1;
          } else {
            tipo = 2;
          }
          infoMessage(tipo, "Mensaje del sistema", result.data.message);
        })
        .catch(console.log);
    }
  }
  render() {
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{ paddingTop: "10px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ padding: "10px" }}>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-5">
                        <label className="control-label">Agente</label>
                        <select
                          className="custom-select"
                          onChange={this.handleInputChange}
                          value={this.state.id_agente}
                          name="id_agente"
                          ref={this.agentesRef}
                        >
                          {this.state.ddAgentes.map((result) => {
                            return (
                              <option value={result.value}>
                                {result.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-5">
                        <label className="control-label">Cliente</label>
                        <select
                          className="custom-select"
                          onChange={this.handleInputChange}
                          value={this.state.id_cliente}
                          name="id_cliente"
                          ref={this.clientesRef}
                        >
                          {this.state.ddClientes.map((result) => {
                            return (
                              <option value={result.value}>
                                {result.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">Tipo De Poliza</label>
                        <select
                          className="custom-select"
                          onChange={this.handleInputChange}
                          value={this.state.id_tipo_poliza}
                          name="id_tipo_poliza"
                          ref={this.tiposPolizasRef}
                        >
                          {this.state.ddTiposPolizas.map((result) => {
                            return (
                              <option value={result.value}>
                                {result.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-3">
                        <label className="control-label">Compañia</label>
                        <select
                          className="custom-select"
                          onChange={this.handleInputChange}
                          value={this.state.id_compania_gmm}
                          name="id_compania_gmm"
                          ref={this.companiaRef}
                        >
                          {this.state.ddCompania.map((result) => {
                            return (
                              <option value={result.value}>
                                {result.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Poliza</label>
                        <input
                          type="text"
                          className="form-control"
                          name="poliza"
                          maxLength="60"
                          value={this.state.poliza}
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">
                          Fecha De Emision
                        </label>
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fecha_emision")
                          }
                          value={this.state.fecha_emision}
                        />
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">
                          Nueva/Renovacion
                        </label>
                        <select
                          className="custom-select"
                          onChange={this.handleInputChange}
                          value={this.state.nueva}
                          name="nueva"
                          ref={this.existenteRef}
                        >
                          {this.state.ddExistente.map((result) => {
                            return (
                              <option value={result.value}>
                                {result.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Detalles</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="detalles"
                        value={this.state.detalles}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <label className="control-label">Asegurado</label>
                        <input
                          type="text"
                          className="form-control"
                          name="asegurado1"
                          value={this.state.asegurado1}
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <label className="control-label">
                          Fecha De Nacimiento
                        </label>
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento1")
                          }
                          value={this.state.fechaDeNacimiento1}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <label className="control-label">Prima Neta</label>
                        <input
                          type="number"
                          className="form-control"
                          step={0.01}
                          maxLength="60"
                          value={this.state.primaNeta1}
                          name="primaNeta1"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.asegurado2}
                          name="asegurado2"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento2")
                          }
                          value={this.state.fechaDeNacimiento2}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          maxLength="60"
                          value={this.state.primaNeta2}
                          name="primaNeta2"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.asegurado3}
                          name="asegurado3"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento3")
                          }
                          value={this.state.fechaDeNacimiento3}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          maxLength="60"
                          value={this.state.primaNeta3}
                          name="primaNeta3"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.asegurado4}
                          name="asegurado4"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento4")
                          }
                          value={this.state.fechaDeNacimiento4}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          maxLength="60"
                          value={this.state.primaNeta4}
                          name="primaNeta4"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.asegurado5}
                          name="asegurado5"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento5")
                          }
                          value={this.state.fechaDeNacimiento5}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          maxLength="60"
                          value={this.state.primaNeta5}
                          name="primaNeta5"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.asegurado6}
                          name="asegurado6"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento6")
                          }
                          value={this.state.fechaDeNacimiento6}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          maxLength="60"
                          value={this.state.primaNeta6}
                          name="primaNeta6"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.asegurado7}
                          name="asegurado7"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento7")
                          }
                          value={this.state.fechaDeNacimiento7}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          maxLength="60"
                          value={this.state.primaNeta7}
                          name="primaNeta7"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.asegurado8}
                          name="asegurado8"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                      <div className="form-group required col-md-2">
                        <DatePicker
                          className="form-control"
                          format="dd/MM/yyyy"
                          onChange={(date) =>
                            this.handleChangeDate(date, "fechaDeNacimiento8")
                          }
                          value={this.state.fechaDeNacimiento8}
                        />
                      </div>
                      <div className="form-group required col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          maxLength="60"
                          value={this.state.primaNeta8}
                          name="primaNeta8"
                          onChange={this.handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listapolizasgmm">
                        <button
                          type="button"
                          className="btn btn-dark btn-block mr-2"
                          style={{ width: "150px" }}
                        >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        style={{ width: "150px" }}
                        onClick={() => this.guardarPoliza()}
                      >
                        <i className="fa fa-save"></i> Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default PolizasGMM;
