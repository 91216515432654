import React, { createElement, useState } from "react";
import "./seguimientos.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { Link } from "react-router-dom";
import infoMessage from "../../components/infoMessage/InfoMessage";
import DatePicker from "react-date-picker";

class Seguimientos extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      loader: false,
      nombre: '',
      telefono: '',
      fecha_nacimiento: new Date(),
      correo: '',
      nota: '',
      id_embudo: '',
      id_prospecto:0,
      nota_recordatorio:'',
      ddEmbudos: [],
      nuevo_recordatorio:'',
      historialSeguimientos:[],
      hora_recordatorio:0,
      hora_nuevo_recordatorio:'15:00:00',
      fecha_nuevo_recordatorio: new Date(),
    };

    this.embudosRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.reloadPage();
    this.checkPerm();
  }

  reloadPage(){
    this.setState({loader: true});
    axios.get('/apiv2/CatalogosController.php/Embudos')
    .then(result => {
      this.setState({
        ddEmbudos: result.data.data,
        embudoSelected: result.data.data[0].value,
        id_embudo: result.data.data[0].value
      });
      this.embudosRef.current.value = parseInt(result.data.data[0].value); 
      if(this.props.match.params.id !== undefined){
        this.setState({loader: true});
        axios.get('/apiv2/SeguimientosController.php/DetallesSeguimientos',{
          params:{
            id:this.props.match.params.id
          }
        })
        .then(result => {
          var datee = new Date(result.data.data.detalle[0].fecha_nacimiento);
          this.setState({
            loader: false,
            nombre: result.data.data.detalle[0].nombre,
            telefono: result.data.data.detalle[0].telefono,
            correo: result.data.data.detalle[0].correo,
            nota: result.data.data.detalle[0].nota,
            id_embudo: result.data.data.detalle[0].id_embudo,
            id_prospecto:result.data.data.detalle[0].id_prospecto,
            nota_recordatorio: result.data.data.detalle[0].nota_recordatorio,
            fecha_nacimiento: new Date(datee.setDate(datee.getDate() + 1)),
            historialSeguimientos: result.data.data.seguimiento
          });

          this.embudosRef.current.value = parseInt(result.data.data.detalle[0].id_embudo);
        })
        .catch(console.log);      
      }
    }).catch(console.log);
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Date]") {
      if (isNaN(event)) {
      } else {
        this.setState({fecha_nuevo_recordatorio: event});
      }
    } else {
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  saveProvider(){
    this.setState({loader: true});
    if(this.props.match.params.id !== undefined){
      axios.put('/apiv2/SeguimientosController.php/Seguimientos',{
        id:this.props.match.params.id,
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        nota: this.state.nota,
        correo: this.state.correo,
        id_embudo: this.state.id_embudo,
        fecha_nacimiento: new Date(this.state.fecha_nacimiento.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        id_usuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }else{
      axios.post('/apiv2/SeguimientosController.php/Seguimientos',
      {
        nombre:this.state.nombre,
        telefono: this.state.telefono,
        nota: this.state.nota,
        correo: this.state.correo,
        id_embudo: this.state.id_embudo,
        fecha_nacimiento: new Date(this.state.fecha_nacimiento.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        id_usuario:localStorage.getItem("NAid")
      })
      .then(result => {
        this.setState({loader: false});
        let tipo = 1;
        if(result.data.success){
          tipo = 1;
        }else{
          tipo = 2;
        }
        infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      })
      .catch(console.log);
    }
  }

  agregarRecordatorio(){
    confirmAlert({
      message: '¿Desea agregar el nuevo recordatorio al cliente?',
      buttons: [
        {
          label: 'Continuar',
          onClick: () => {
            this.setState({loader: true});
            axios.post('/apiv2/SeguimientosController.php/Recordatorios',
            {
              recordatorio: this.state.nuevo_recordatorio, 
              fecha_recordatorio: new Date(this.state.fecha_nuevo_recordatorio.setHours(0, 0, 0, 0)).toISOString().split('T')[0], 
              hora_recordatorio: this.state.hora_nuevo_recordatorio, 
              id_prospecto: this.state.id_prospecto, 
              id_usuario: localStorage.getItem("NAid"),
            })
            .then(result => {
              this.setState({loader: false});
              let tipo = 1;
              if(result.data.success){
                tipo = 1;
              }else{
                tipo = 2;
              }
              infoMessage(tipo, 'Mensaje del sistema', result.data.message);
              setTimeout(() => {
                this.reloadPage();
              },1200);
            })
            .catch(console.log);
          }
        },
        {
          label: 'Cancelar'
        }
      ],
      overlayClassName: 'backGroundDialog'
    });
  }

  agregarComentario(){
    this.setState({loader: true});
    axios.post('/apiv2/SeguimientosController.php/Comentarios',
    {
      comentario: this.state.comentario_nuevo, 
      id_prospecto: this.state.id_prospecto, 
      id_usuario: localStorage.getItem("NAid"),
    })
    .then(result => {
      this.setState({loader: false});
      let tipo = 1;
      if(result.data.success){
        tipo = 1;
      }else{
        tipo = 2;
      }
      infoMessage(tipo, 'Mensaje del sistema', result.data.message);
      setTimeout(() => {
        this.reloadPage();
      },1200);
    })
    .catch(console.log);
  }

  deleteSeguimientos(){
    confirmAlert({
      message: (
        <div>
          <p>¿Desea eliminar el recordatorio?</p>
        </div>
      ),
      buttons: [
        {
          label: 'Continuar',
          onClick: () => {
            this.setState({loader: true});
            axios.delete('/apiv2/SeguimientosController.php/Seguimientos?id='+this.props.match.params.id+'&id_usuario='+localStorage.getItem("NAid"))
              .then(result => {
                this.setState({loader: false});
                let tipo = 1;
                if(result.data.success){
                  tipo = 1;
                }else{
                  tipo = 2;
                }
                infoMessage(tipo, 'Mensaje del sistema', result.data.message);
              })
            .catch(console.log);
            }
          },
          {
            label: 'Cancelar'
          }
        ],
      overlayClassName: 'backGroundDialog'
    });  
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="backGroundDialog"
      >
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <div className="card" style={{padding:"10px"}}>
                  <div className="card-header">
                    <h3 className="card-title"><b>Recordatorio</b></h3>
                  </div>
                  <form>
                    <div className="form-row">
                      <div className="form-group required col-md-6">
                        <label className="control-label">Nombre</label>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" 
                        maxLength="60" value={this.state.nombre} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group required col-md-6">
                        <label className="control-label">Correo</label>
                        <input type="text" className="form-control" placeholder="Correo" name="correo" 
                        maxLength="60" value={this.state.correo} onChange={this.handleInputChange} disabled></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group required col-md-4">
                        <label className="control-label">Telefono</label>
                        <input type="number" className="form-control" placeholder="Telefono" name="telefono"
                        maxLength="10" value={this.state.telefono} onChange={this.handleInputChange} disabled></input>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Fecha De Nacimiento</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_nacimiento} disabled/>
                      </div>
                      <div className="form-group required col-md-4">
                        <label className="control-label">Embudo</label>
                        <select class="custom-select" onChange={this.handleInputChange} 
                          value={this.state.id_embudo} name="id_embudo" ref={this.embudosRef} disabled>
                          {this.state.ddEmbudos.map((result) => {
                            return(
                              <option value={result.value}>{result.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Nota</label>
                      <textarea type="text" className="form-control" name="nota" rows="4"
                        value={this.state.nota} onChange={this.handleInputChange} disabled></textarea>
                    </div>
                    <div className="form-group">
                      <label for="inputAddress">Recordatorio</label>
                      <textarea type="text" className="form-control" name="nota_recordatorio" rows="4"
                        value={this.state.nota_recordatorio} onChange={this.handleInputChange} disabled></textarea>
                    </div>
                    <div className="form-row justify-content-md-center">
                      <Link to="/listaseguimientos">
                        <button type="button" 
                          className="btn btn-danger btn-block mr-2" 
                          style={{width: "150px"}} >
                          <i className="fa fa-arrow-circle-left"></i> Volver
                        </button>
                      </Link>
                      <button type="button" 
                        className="btn btn-success btn-block" 
                        style={{width: "150px"}}
                        onClick={() => this.deleteSeguimientos()} >
                        <i className="fa fa-thumbs-up"></i> Atendido
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-5">
                <div className="card" style={{padding:"10px"}}>
                  <form>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>OPCIONES DE LISTA</b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label for="inputAddress">Recordatorio Nuevo</label>
                        <textarea type="text" className="form-control" name="nuevo_recordatorio"
                          value={this.state.nuevo_recordatorio} onChange={this.handleInputChange}></textarea>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="control-label">Fecha</label>
                        <DatePicker className="form-control" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                          value={this.state.fecha_nuevo_recordatorio} />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="control-label">Hora(HH:MM:SS)</label>
                        <input type="text" className="form-control" placeholder="Hora" name="hora_nuevo_recordatorio" 
                        maxLength="60" value={this.state.hora_nuevo_recordatorio} onChange={this.handleInputChange}></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.agregarRecordatorio()} >
                          <i className="fa fa-plus-circle"></i> Agregar Nuevo Recordatorio
                        </button>
                      </div>
                    </div>
                    <div class="card-header">
                      <h3 class="card-title">
                        <b></b>
                      </h3>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label for="inputAddress">Nuevo Comentario</label>
                        <textarea type="text" className="form-control" name="comentario_nuevo"
                          value={this.state.comentario_nuevo} onChange={this.handleInputChange}></textarea>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <button type="button" 
                          className="btn btn-block bg-gradient-success mt-4" 
                          onClick={() => this.agregarComentario()} >
                          <i className="fa fa-plus-circle"></i> Agregar Comentario
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{padding:"10px"}}>
                  <div class="card-header">
                    <h3 class="card-title">
                      <b>Historial De Seguimiento (Ultimos 8)</b>
                    </h3>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.historialSeguimientos.map((result) => {
                        return(
                          <tr>
                            <td style={{whiteSpace: "pre-line"}}>{result.comentario}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Seguimientos;
