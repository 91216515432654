import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Login from "./pages/login/Login";
import reportWebVitals from './reportWebVitals';

class CheckUser extends React.Component {

	constructor(props) {
    super(props);
    this.state = {isLoggedIn: false};
  }

  componentDidMount(){
  	if(localStorage.getItem('NAid') === null){
  		this.setState({isLoggedIn:false});
  	}else{
  		this.setState({isLoggedIn:true});
  	}
  }

	render(){
		const isLoggedIn = this.state.isLoggedIn;
    if (isLoggedIn) {
      return(<App />);
    } else {
      return(<Login />);
    }
	}
}

ReactDOM.render(
	<CheckUser />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
