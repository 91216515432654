import React from 'react';
import "./reportePagosAgentes.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import RingLoader from 'react-spinners/RingLoader';
import {Link} from 'react-router-dom';
import infoMessage from '../../components/infoMessage/InfoMessage';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

class ReportePagosAgentes extends React.Component{
  constructor(props) {
    super(props);

    let fecha = new Date();

    this.state = {
      dataTable: [],
      dataTablePagos: [],
      dataTablePagosV2: [],
      filtro: '',
      loader: true,
      filtroFechas:[ 
        new Date(fecha.setDate(fecha.getDate()-7)),
        new Date(fecha.setDate(fecha.getDate()+7))
      ],
      selectedRow: 0,
      ddTipoPagosAgentes: [
        {
          value:0,
          text:"TODOS"
        },
        {
          value:1,
          text:"SEMANAL"
        },
        {
          value:2,
          text:"MENSUAL"
        },
        {
          value:3,
          text:"ESPECIAL"
        }
      ],
      id_tipo_pago_agente:0,
      id_agente:0
    };
    this.loadTableData();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.tipoPagosAgentesRef = React.createRef();
    this.checkPerm();
  }

  checkPerm = async() => {
    var url = window.location.href;
    var lastSegment = url.split("/");
    var ruta = lastSegment[3];
    var perms = JSON.parse(localStorage.getItem('NApermisos'));
    var rett = true;
    await Promise.all(perms.map(async (perm) => {
      if(perm.uri == ruta){
        rett = false;
        return false;
      }
    }));
    if(rett){
      this.props.history.push("/error");
    }
  }

  loadTableData = () => {
    this.setState({loader: true});
    axios.get('/apiv2/PagosAgentesController.php/Agentes',
    {
      params:{
        q:this.state.filtro,
        fechaInicial:new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        fechaFinal:new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTable: result.data.data,
          dataTablePagosV2: [],
        });
      },1000);
    })
    .catch(console.log);
  }

  loadTableDataPagos = (id) => {
    this.loadTableDataPagosV2(id);
    /*this.setState({loader: true});
    axios.get('/apiv2/PagosAgentesController.php/Pagos',
    {
      params:{
        q:this.state.filtro,
        //fechaInicial:this.state.filtroFechas[0].toISOString().split('T')[0],
        //fechaFinal:this.state.filtroFechas[1].toISOString().split('T')[0],
        id:id,
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTablePagos: result.data.data
        });
      },1000);
    })
    .catch(console.log);*/
  }

  loadTableDataPagosV2 = (id) => {
    this.setState({loader: true});
    axios.get('/apiv2/PagosAgentesController.php/PagosV2',
    {
      params:{
        q:this.state.filtro,
        //fechaInicial:this.state.filtroFechas[0].toISOString().split('T')[0],
        fechaInicial: new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        //fechaInicial:this.state.filtroFechas[1].toISOString().split('T')[0],
        fechaFinal: new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0],
        id:id,
      }
    })
    .then(result => {
      setTimeout(() => {
        this.setState({loader: false});
        this.setState({
          dataTablePagosV2: result.data.data,
          id_agente:id
        });
      },1000);
    })
    .catch(console.log);
  } 

  handleChange(event) {
    this.setState({filtro: event.target.value});
  }

  handleChangeDate(event) {
    if (Object.prototype.toString.call(event) === "[object Array]") {
        this.setState({filtroFechas: event});
    }
  }

  handleSubmit(event) {
    /*this.setState({loader: true});
    setTimeout(() => {
      this.loadTableData();
    }, 800);*/
    event.preventDefault();
  }
  
  selectRow = (id) => {
    this.setState({
      selectedRow: id,
      currentPagePagos: 0,
      totalPagesPagos: 1,
      limitPagePagos: 10,
    });
    this.loadTableDataPagos(id);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      if(target.checked){
          this.state.hobbies[value] = value;   
      }else{
          this.state.hobbies.splice(value, 1);
      }
    }else{
      this.setState({
          [name]: value
      });
    }  
  }

  generarPDF(){
    //var fecha1 = this.state.filtroFechas[0].toISOString().split('T')[0];
    var fecha1 = new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0];
    //var fecha2 = this.state.filtroFechas[1].toISOString().split('T')[0];
    var fecha2 = new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0];
    window.open('/apiv2/ReportesController.php/PagosPolizasAutomovil?'
      +'filtro='+this.state.filtro
      +'&fechaInicial='+fecha1
      +'&fechaFinal='+fecha2
      ).focus();
  }

  initialPage = (tipo) => {
    if(tipo == 1){
      this.setState({loader: true});
      this.setState({
        currentPage : 0
      });
      setTimeout(() => {
        this.loadTableData();
      }, 800);
    }else{
      this.setState({loader: true});
      this.setState({
        currentPagePagos : 0
      });
      setTimeout(() => {
        this.loadTableDataPagos(this.state.selectedRow);
      }, 800);     
    }
  }

  prevPage = (tipo) => {
    if(tipo == 1){
      this.setState({loader: true});
      this.setState({
        currentPage :Math.max(0, (this.state.currentPage - 1))
      });
      setTimeout(() => {
        this.loadTableData();
      }, 800);
    }else{
      this.setState({loader: true});
      this.setState({
        currentPagePagos :Math.max(0, (this.state.currentPagePagos - 1))
      });
      setTimeout(() => {
        this.loadTableDataPagos(this.state.selectedRow);
      }, 800);
    }
  }

  nextPage = (tipo) => {
    if(tipo == 1){
      this.setState({loader: true});
      this.setState({
        currentPage : Math.min(this.state.totalPages, (this.state.currentPage + 1))
      });
      setTimeout(() => {
        this.loadTableData();
      }, 800);
    }else{
      this.setState({loader: true});
      this.setState({
        currentPagePagos : Math.min(this.state.totalPagesPagos, (this.state.currentPagePagos + 1))
      });
      setTimeout(() => {
        this.loadTableDataPagos(this.state.selectedRow);
      }, 800);
    }
  }

  lastPage = (tipo) => {
    if(tipo == 1){
      this.setState({loader: true});
      this.setState({
        currentPage : this.state.totalPages -1
      });
      setTimeout(() => {
        this.loadTableData();
      }, 800);
    }else{
      this.setState({loader: true});
      this.setState({
        currentPagePagos : this.state.totalPagesPagos - 1
      });
      setTimeout(() => {
        this.loadTableDataPagos(this.state.selectedRow);
      }, 800);
    }
  }

  generarPDF(idAgente, total){
    window.open('/apiv2/ReportesController.php/DesglosePagoAgente?'
      +'idagente='+idAgente
      +'&total='+total
      //+'&fechainicial='+this.state.filtroFechas[0].toISOString().split('T')[0]
      //+'&fechafinal='+this.state.filtroFechas[1].toISOString().split('T')[0]
      +'&fechainicial='+ new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0]
      +'&fechafinal='+ new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0]
      ).focus();
  }

  generarPDFEspecial(idAgente, total){
    window.open('/apiv2/ReportesController.php/DesglosePagoAgenteOmar?'
      +'idagente='+idAgente
      +'&total='+total
      //+'&fechainicial='+this.state.filtroFechas[0].toISOString().split('T')[0]
      //+'&fechafinal='+this.state.filtroFechas[1].toISOString().split('T')[0]
      +'&fechainicial='+ new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0]
      +'&fechafinal='+ new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0]
      ).focus();
  }

  generarPDFSinRCs(idAgente, total){
    window.open('/apiv2/ReportesController.php/DesglosePagoAgenteSinRC?'
      +'idagente='+idAgente
      +'&total='+total
      //+'&fechainicial='+this.state.filtroFechas[0].toISOString().split('T')[0]
      //+'&fechafinal='+this.state.filtroFechas[1].toISOString().split('T')[0]
      +'&fechainicial='+ new Date(this.state.filtroFechas[0].setHours(0, 0, 0, 0)).toISOString().split('T')[0]
      +'&fechafinal='+ new Date(this.state.filtroFechas[1].setHours(0, 0, 0, 0)).toISOString().split('T')[0]
      ).focus();
  }

  aplicarPago(id, cantidad, agente_subagente, tipo_comision){
    this.setState({loader: true});
    axios.get('/apiv2/PagosAgentesController.php/AplicarPago',
    {
      params:{
        id:id,
        cantidad:cantidad,
        agente_subagente:agente_subagente,
        id_agente:this.state.id_agente,
        tipo_comision:tipo_comision,
        id_usuario:localStorage.getItem('NAid')
      }
    })
    .then(result => {
      this.loadTableDataPagosV2(this.state.id_agente);
    })
    .catch(console.log);
  }

  render(){
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<RingLoader />}
        className="container-fluid min-vh-100"
      >
      	<div className="wrapper" style={{paddingTop:"10px"}}>
          <div className="container-fluid">
            <div className="row">
            	<div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h3>Pagos Agentes</h3>
                  </div>
                  <div className="card-header d-flex justify-content-start">
                  	<form onSubmit={this.handleSubmit}>  
                      <div className="input-group input-group-sm mr-2" style={{width: "200px"}}>
                        <input type="text" className="form-control" placeholder="Buscar" 
                          value={this.state.filtro} onChange={this.handleChange}>
                        </input>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mr-2">
                      <DateRangePicker className="mr-2" format="dd/MM/yyyy" onChange={this.handleChangeDate} 
                        value={this.state.filtroFechas}/>
                    </div>
                    <div className="mr-2">
                      <button type="button" 
                        className="btn btn-info btn-block btn-sm" 
                        style={{width: "40px"}} 
                        onClick={() => this.loadTableData()}>
                        <i className="fa fa-sync"></i>
                      </button>
                    </div>
                    <div className="mr-2" style={{width: "200px"}}>
                      <select class="custom-select" onChange={this.handleInputChange} 
                        value={this.state.id_tipo_pago_agente} name="id_tipo_pago_agente" ref={this.tipoPagosAgentesRef}>
                        {this.state.ddTipoPagosAgentes.map((result) => {
                          return(
                            <option value={result.value}>{result.text}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="card" style={{padding:"10px"}}>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-row">
                        <div className="form-group required col-md-12">
                          <div className="table-wrapper-scroll-y my-custom-scrollbar">
                            <table className="table table-bordered table-striped mb-0">
                              <thead>
                                <tr className="Table-Details">
                                  <th>Agente</th>
                                  <th>Cantidad</th>
                                  <th>Acciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataTable.map((result) => {
                                  return(
                                    <tr className={this.state.selectedRow == result.id ? 'Selected-Table-Row': null}
                                    onDoubleClick={() => this.selectRow(result.id)}>
                                      { (result.id_tipo_pago_agente == this.state.id_tipo_pago_agente || this.state.id_tipo_pago_agente == 0) && 
                                        <td>
                                          {result.nombre} 
                                          <span style={{marginLeft:"10px"}} className={result.class_name}>{result.tipo}</span>
                                          <span style={{marginLeft:"10px"}} className="badge bg-warning">{result.porcentaje}%</span>
                                          <span style={{marginLeft:"10px"}} className="badge bg-dark">{result.tarjeta}</span>
                                        </td>
                                      }
                                      { (result.id_tipo_pago_agente == this.state.id_tipo_pago_agente || this.state.id_tipo_pago_agente == 0) && 
                                        <td>{result.total_ventas}</td>
                                      }
                                      { (result.id_tipo_pago_agente == this.state.id_tipo_pago_agente || this.state.id_tipo_pago_agente == 0) && 
                                        <td> 
                                          <div class="d-flex justify-content-center">
                                            { result.id != '21' && 
                                              <div className="input-group-append mr-2">
                                                <button type="submit" className="btn btn-danger btn-sm"
                                                onClick={() => this.generarPDF(result.id, result.total_ventas)}>
                                                  <i className="fas fa-file-pdf"></i>
                                                </button>
                                              </div>
                                            }
                                            { result.id == '21' && 
                                              <div className="input-group-append mr-2">
                                                <button type="submit" className="btn btn-info btn-sm"
                                                onClick={() => this.generarPDFEspecial(result.id, result.total_ventas)}>
                                                  <i className="fas fa-file-pdf"></i>
                                                </button>
                                              </div>
                                            }
                                            <div className="input-group-append mr-2">
                                                <button type="submit" className="btn btn-warning btn-sm"
                                                onClick={() => this.generarPDFSinRCs(result.id, result.total_ventas)}>
                                                  <b><del>RC</del></b>
                                                </button>
                                              </div>
                                          </div>
                                        </td>
                                      }
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group required col-md-12">
                          <div className="table-wrapper-scroll-y my-custom-scrollbar">
                            <table className="table table-bordered table-striped mb-0">
                              <thead>
                                <tr className="Table-Details">
                                  <th>Poliza</th>                                  
                                  <th>Cliente</th>
                                  <th>Descripcion</th>
                                  <th>Cantidad</th>
                                  <th>Fecha registro</th>
                                  <th>Acciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataTablePagosV2.map((result) => {
                                  return(
                                    <tr>
                                      <td>
                                        {result.poliza}
                                        <span style={{marginLeft:"10px"}} className={(result.pago_trabajador == 0 ? "badge bg-danger" : "badge bg-success")}>
                                          {(result.pago_trabajador == 0 ? "Pendiente" : "Pagado")}
                                        </span>
                                      </td>                                      
                                      <td>{result.cliente}</td>
                                      <td>{result.descripcion}</td>
                                      <td>{result.cantidad}</td>
                                      <td>{result.fecha_registro}</td>
                                      <td> 
                                        { result.pago_trabajador == 0 &&
                                          <div class="d-flex justify-content-center">
                                            <div className="input-group-append mr-2">
                                              <button type="submit" className="btn btn-success btn-sm"
                                              onClick={() => this.aplicarPago(result.id, result.cantidad_real, result.agente_subagente, result.tipo_comision)}>
                                                <i className="fas fa-hand-holding-usd"></i>
                                              </button>
                                            </div>
                                          </div>
                                        }
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </LoadingOverlay>
    );
  }
}

export default ReportePagosAgentes;